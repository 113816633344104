import { PlusIcon } from "@heroicons/react/24/outline"
import { Form } from "antd"
import React, { useState } from "react"
import { Button } from "components"

import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"

import getIdFromKey from "utils/getIdFromKey"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { CustomQuestionCard } from "../component/QuestionCard"

import CustomQuestionForm from "../component/CustomQuestionForm"
import DeleteQuestion from "../modals/DeleteQuestion"
import { useNavigate } from "react-router-dom"
import { IFinishTempBody, ISurveyTemplateData } from "../interface/templates"

export const AddTemplateQuestion: React.FC<{
  activeKey: boolean
  setActiveKey: Function
  showNew: boolean
  setShowNew: Function
  surveyTemplateData: ISurveyTemplateData
  invalidateQueries: () => void
  x?: string | null
  isLoading: boolean
}> = ({
  activeKey,
  setActiveKey,
  showNew,
  setShowNew,

  surveyTemplateData,
  invalidateQueries,
  isLoading,
  x,
}) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeletId, setActiveDeleteId] = useState("")

  const handleOpenDelete = (id: any) => {
    console.log(id)

    setOpenDeleteConfirmation(true)
    setActiveDeleteId(id)
  }
  // FINISH SURVEY
  const { isLoading: isLoadingEdit, mutate: FinishTemplate } = useMutation({
    mutationFn: async (body: IFinishTempBody) => {
      const res = await axiosInstance.post(
        `/admin/surveys/finish_template`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey`)

        // toast.success("Template has been created successfully")
      }
    },
  })
  const onFinish = () => {
    if (x) {
      FinishTemplate({
        survey_template_id: x ? getIdFromKey(x || "") : "-1",
        survey_template_type_id: "3",
        description: surveyTemplateData?.template?.description,
        alias: surveyTemplateData?.template?.alias,
      })
    }
  }

  return (
    <FormSectionWrapper
      name="Template questions"
      title="Template questions"
      subtitle="Create survey questions to add to template"
      isActive={activeKey}
      layout="vertical"
      form={form}
      // onFinish={onFinishQuestion}
      style={{ maxWidth: 1024 }}
      autoComplete="off"
      showDropdown
      onDropDown={() => {
        setActiveKey("2")
      }}
      footerBtns={
        <div className="flex gap-3 justify-end mt-4">
          <Button
            color="secondary"
            title="Previous"
            onClick={() => navigate(-1)}
          >
            Previous
          </Button>

          <Button
            color="primary"
            title="Submit"
            onClick={onFinish}
            submitting={isLoadingEdit}
          >
            Finish
          </Button>
        </div>
      }
      className="font-avenir"
    >
      <div className="flex flex-col  gap-1 px-4 md:px-6 xl:px-[60px]">
        <h6 className=" text-neutral700 font-extrabold text-sm">
          Template Questions
        </h6>
        <div className="flex flex-col gap-4">
          {isLoading ? (
            <div className="w-full flex  justify-center">
              <LoadingIndicatorWhite />
            </div>
          ) : surveyTemplateData?.template?.questions.length > 0 ? (
            surveyTemplateData?.template?.questions?.map((quest, index) => (
              <>
                <CustomQuestionCard
                  id={quest.survey_template_question_id}
                  key={index}
                  question_title={quest.description}
                  question={quest.alias}
                  response_type_id={String(quest?.review_response_type_id)}
                  type={quest?.response_options}
                  handleOpenDelete={handleOpenDelete}
                  invalidateQueries={invalidateQueries}
                  editUrl={`/admin/surveys/edit_template_question?id=${quest.survey_template_question_id}`}
                />
              </>
            ))
          ) : (
            <div className="h-20 p flex justify-center items-center">
              No available template question
            </div>
          )}
        </div>
        {showNew && (
          <CustomQuestionForm
            formTitle="Add Template Question"
            url={`/admin/surveys/add_template_question?id=${getIdFromKey(
              x || ""
            )}`}
            invalidateQueries={invalidateQueries}
            onClose={() => setShowNew(false)}
          />
        )}

        {!showNew && (
          <button
            className="mt-6   text-sm text-primary500 flex items-center gap-2 mb-3"
            onClick={() => {
              setShowNew(!showNew)
            }}
          >
            <PlusIcon className="w-4 stroke-2" />
            <p>Add a question</p>
          </button>
        )}

        <DeleteQuestion
          title="Delete Template Question"
          open={openDeleteConfirmation}
          onClose={() => {
            setOpenDeleteConfirmation(false)
            setActiveDeleteId("")
          }}
          invalidateQueries={invalidateQueries}
          activeDeleteId={activeDeletId}
          url={`/admin/surveys/delete_template_question?id=${activeDeletId}`}
        />
      </div>
    </FormSectionWrapper>
  )
}
