import { Tag } from "antd"
import { ISurveyData, SurveyQuestion } from "../interface"
import { surveyResponseTypes } from "."
import { SurveyTemplateQuestion } from "../interface/templates"

const PreviewQuestion = ({
  data,
}: {
  data: SurveyQuestion[] | SurveyTemplateQuestion[]
}) => {
  return (
    <div className="flex flex-col gap-4">
      {data?.map((item: any, index: any) => {
        const responseType = surveyResponseTypes?.find(
          (survey) => survey.id === String(item?.review_response_type_id)
        )
        return (
          <div
            key={index}
            className="border w-full gap-5 min-h-[78px] flex  items-center justify-between p-2 shadow-sm  "
          >
            <div className=" flex flex-col  flex-grow gap-2 h-full justify-center">
              <h6 className=" text-neutral700   font-extrabold text-sm">
                {item?.description}
              </h6>{" "}
              <p className="text-neutral500 text-sm">{item?.alias}</p>
              <p className="text-neutral500 text-sm">
                Response type: <Tag color="blue">{responseType?.title}</Tag>
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default PreviewQuestion
