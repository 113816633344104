import React, { useState } from "react"

import { Dropdown, Tag } from "antd"
import { surveyResponseTypes } from "."
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import CustomQuestionForm from "./CustomQuestionForm"

export const CustomQuestionCard: React.FC<{
  question_title: string
  question: string
  id: any
  type: string
  response_type_id: string
  handleOpenDelete: (e: any) => void
  invalidateQueries: () => void
  editUrl: string
}> = ({
  editUrl,
  question,
  id,
  question_title,
  type,
  response_type_id,
  handleOpenDelete,
  invalidateQueries,
}) => {
  const [showEdit, setShowEdit] = useState(false)

  const actionItems = () => {
    let arrayOptions: any = []
    {
      arrayOptions = [
        ...arrayOptions,
        {
          key: "0",
          label: (
            <div
              className="w-full"
              onClick={() => setShowEdit(true)}
            >
              Edit{" "}
            </div>
          ),
        },
      ]
    }
    {
      arrayOptions = [
        ...arrayOptions,
        {
          key: "1",
          label: (
            <div
              className="w-full"
              onClick={() => handleOpenDelete(id)}
            >
              Delete
            </div>
          ),
        },
      ]
    }

    return arrayOptions
  }
  const responseType = surveyResponseTypes?.find(
    (item) => item.id === response_type_id
  )
  return (
    <div className="border border-neutral40 rounded-5px p-2">
      <div className="  w-full gap-5 min-h-[70px] flex  items-center justify-between px-2  ">
        <div className=" flex flex-col  flex-grow gap-1 h-full justify-center">
          <h6 className=" text-neutral700 font-semibold text-sm">
            {question_title}
          </h6>{" "}
          <p className="text-neutral500 text-sm">{question}</p>
          <p className="text-neutral500 text-sm">
            Response type: <Tag color="blue">{responseType?.title}</Tag>
          </p>
        </div>
        <div className="">
          <Dropdown
            menu={{
              items: actionItems(),
            }}
          >
            <button>
              <EllipsisVerticalIcon className="w-6 h-6 text-[#004AF5]" />
            </button>
          </Dropdown>
        </div>
      </div>
      {showEdit && (
        <div className="mt-2">
          <CustomQuestionForm
            formTitle="Edit Question"
            url={editUrl}
            currTitle={question_title}
            currDescription={question}
            currResponseId={response_type_id}
            currResponseValue={type}
            invalidateQueries={invalidateQueries}
            onClose={() => setShowEdit(false)}
          />
        </div>
      )}
    </div>
  )
}
