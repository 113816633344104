import React from "react"
import { Navigate, Route } from "react-router-dom"

import { Spin } from "antd"
import EmployeeProfile from "pages/employee-dashboard/profile/EmployeeProfile"
import ViewReviewListing from "pages/employee-dashboard/reviews/ViewReviewListing"
import ViewReviewDetailed from "pages/employee-dashboard/reviews/ViewReviewDetailed"
import ViewReviewsEmployee from "pages/employee-dashboard/reviews/ViewReviewEmployee"
import DownloadPayslip from "pages/employee-dashboard/payslip/downloadPayslips"
import NewResponsiveEmployeeLayout from "components/new-stuff/layout/employee/EmployeeLayout"
import { ViewViolationPage } from "pages/employee-dashboard/disciplinary/ViewViolationPage"
import TwoFaSetupPage from "pages/2fa/TwoFaSetUpPage"
import EarnedWageAccess from "pages/employee-dashboard/wallet/ewa/EarnedWageAccess"
import Transactions from "pages/employee-dashboard/wallet/ewa/transactions/Transanctions"
import Withdrawal from "pages/employee-dashboard/wallet/ewa/Withdrawal"
import Wallet from "pages/employee-dashboard/wallet/wallet/Wallet"
import KYC from "pages/employee-dashboard/wallet/wallet/kyc/KYC"
import CreatePin from "pages/employee-dashboard/wallet/wallet/pin/CreatePin"
import Beneficiaries from "pages/employee-dashboard/wallet/wallet/beneficiaries/Beneficiaries"
import SuspendedExitedEmployeeLayout from "components/new-stuff/layout/suspended-exited-employee/SuspendedExitedEmployeeLayout"

// CROSS_BORDER
import CrossBorderEmployee from "pages/employee-dashboard/wallet/wallet/crossBorder/Overview"
import SwapMoneyEmp from "pages/employee-dashboard/wallet/wallet/crossBorder/SwapMoney"
import CrossBorderTransEmp from "pages/employee-dashboard/wallet/wallet/crossBorder/transactions/Transactions"
import ViewCurrencyEmp from "pages/employee-dashboard/wallet/wallet/crossBorder/viewcurrency/ViewCurrency"

import SendMoneyEmpCrossBorder from "pages/employee-dashboard/wallet/wallet/crossBorder/viewcurrency/sendMoney/SendMoneyEmpCB"
import MoveFundsEmp from "pages/employee-dashboard/wallet/wallet/crossBorder/MoveFundsEmp"

import ViewTransaction from "pages/employee-dashboard/wallet/wallet/crossBorder/transactions/ViewTransaction"
import CrossBorderTransfers from "pages/employee-dashboard/wallet/wallet/crossBorder/transfers/Transfers"
import SetEWAPin from "pages/employee-dashboard/wallet/ewa/SetEWAPin"
import CreateBeneficiary from "pages/employee-dashboard/wallet/wallet/beneficiaries/CreateBeneficiary"
import { ViewEmpSurvey } from "pages/employee-dashboard/survey/ViewSurvey"

const Dashboard = React.lazy(
  () => import("pages/employee-dashboard/Dashboard/Dashboard")
)

const EmployeeOnboarding = React.lazy(
  () => import("pages/employee-dashboard/onboarding")
)

const JoyAndPay = React.lazy(
  () => import("pages/employee-dashboard/job-and-pay/JobAndPay")
)
const Survey = React.lazy(
  () => import("pages/employee-dashboard/survey/Survey")
)
const Personal = React.lazy(
  () => import("pages/employee-dashboard/personal/Personal")
)
const OrganisationChart = React.lazy(
  () => import("pages/dashboard/people/people/org-chart/OrganisationChart")
)

// const RequestLeaves = React.lazy(
//   () => import("pages/employee-dashboard/requests/LeaveRequests")
// )

const EmployeeAllRequest = React.lazy(
  () => import("pages/employee-dashboard/requests/EmployeeAllRequest")
)

const CreateExitRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateExitRequest")
)

const CreateLeaveRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateLeaveRequest")
)

const CreateLoanRequest = React.lazy(
  () =>
    import("pages/employee-dashboard/requests/createRequest/CreateLoanRequest")
)

const CreateSalaryAdvance = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateSalaryAdvanceRequest"
    )
)

const CreateRedeploymentRequest = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateRedeploymentRequest"
    )
)

const CreateDocumentRequest = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/requests/createRequest/CreateDocumentRequest"
    )
)

const LeaveBalances = React.lazy(
  () => import("pages/employee-dashboard/requests/components/LeaveBalances")
)

const CreateViolationRequest = React.lazy(
  () => import("pages/employee-dashboard/disciplinary/CreateViolationRequest")
)

const Document = React.lazy(
  () => import("pages/employee-dashboard/documents/Documents")
)

const Benefits = React.lazy(
  () => import("pages/employee-dashboard/benefits/Benefit")
)
const Payables = React.lazy(
  () => import("pages/employee-dashboard/payables/Payables")
)
const CreateNewPayableRequest = React.lazy(
  () => import("pages/employee-dashboard/payables/CreateNewPayableRequest")
)
const Payslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/Payslip")
)

const ViewPayslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/viewPayslip")
)
const ViewAllPayslip = React.lazy(
  () => import("pages/employee-dashboard/payslip/viewAllPayslips")
)

const BillPayments = React.lazy(
  () =>
    import("pages/employee-dashboard/wallet/wallet/bill-payments/BillPayments")
)
const BillPaymentsTransactions = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/wallet/wallet/bill-payments/BillPaymentsTransactions"
    )
)
const BillPaymentsTransactionDetails = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/wallet/wallet/bill-payments/BillPaymentsTransactionDetails"
    )
)
const Buy = React.lazy(
  () => import("pages/employee-dashboard/wallet/wallet/bill-payments/buy/Buy")
)
const ChristmasVillage = React.lazy(
  () => import("pages/employee-dashboard/christmas-village/ChristmasVillage")
)

const Assets = React.lazy(
  () => import("pages/employee-dashboard/assets/Assets")
)

const Tasks = React.lazy(() => import("pages/employee-dashboard/tasks/Task"))
const ViewTask = React.lazy(
  () => import("pages/employee-dashboard/tasks/EditRoutineTaskForEmployee")
)

// const Performance = React.lazy(
//   () => import("pages/employee-dashboard/performance/Performance")
// )

const Disciplinary = React.lazy(
  () => import("pages/employee-dashboard/disciplinary/Disciplinary")
)

const Notes = React.lazy(() => import("pages/employee-dashboard/notes/Notes"))

const PerformanceOverview = React.lazy(
  () => import("pages/employee-dashboard/performance/overview/Overview")
)
const PerformanceObjectives = React.lazy(
  () => import("pages/employee-dashboard/performance/objectives/Objectives")
)
const Feedback = React.lazy(
  () => import("pages/employee-dashboard/performance/feedback/Index")
)

const RequestFeedback = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/performance/feedback/requests/RequestFeedback"
    )
)

const SendMoneyBank = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/wallet/wallet/crossBorder/viewLocal/sendMoney/SendMoneyBank"
    )
)
const SendMoneyWallet = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/wallet/wallet/crossBorder/viewLocal/sendMoney/SendMoneyWallet"
    )
)
const ViewLocalCurrency = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/wallet/wallet/crossBorder/viewLocal/ViewLocalCurrency"
    )
)

const GiveFeedback = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/performance/feedback/feedback/GiveFeedback"
    )
)
const Conversation = React.lazy(
  () => import("pages/employee-dashboard/performance/conversation/Index")
)
const CreateConversation = React.lazy(
  () =>
    import(
      "pages/employee-dashboard/performance/conversation/setup/CreateConversation"
    )
)

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0  left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}

const EmployeeDashboard = () => {
  return (
    <>
      {/* suspended or exited employees */}
      <Route
        path="/employee/susp-exit"
        element={<SuspendedExitedEmployeeLayout />}
      >
        <Route
          index
          element={<Navigate to={"payslips"} />}
        />
        <Route
          path="payslips"
          element={<Payslip />}
        />
        <Route
          path="payslip/view-payslip"
          element={<ViewPayslip />}
        />
        <Route
          path="payslip/download-payslip"
          element={<DownloadPayslip />}
        />
        <Route
          path="payslip/all-payslips"
          element={<ViewAllPayslip />}
        />
        {/* CROSS-BORDER PAYMENT  */}
        <Route
          path="wallet/cross-border/overview"
          element={<CrossBorderEmployee />}
        />
        <Route
          path="wallet/cross-border/local"
          element={<ViewLocalCurrency />}
        />
        <Route
          path="wallet/cross-border/transactions"
          element={<CrossBorderTransEmp />}
        />
        <Route
          path="wallet/cross-border/transfers"
          element={<CrossBorderTransfers />}
        />
        <Route
          path="wallet/cross-border/view"
          element={<ViewCurrencyEmp />}
        />
        <Route
          path="wallet/cross-border/swap-money"
          element={<SwapMoneyEmp type="employee" />}
        />
        <Route
          path="wallet/cross-border/send-money"
          element={<SendMoneyEmpCrossBorder />}
        />
        <Route
          path="wallet/cross-border/move-funds"
          element={<MoveFundsEmp />}
        />
        <Route
          path="wallet/overview"
          element={<Wallet />}
        />
        <Route
          path="wallet/kyc"
          element={<KYC />}
        />
        <Route
          path="wallet/beneficiaries"
          element={<Beneficiaries />}
        />
        <Route
          path="wallet/beneficiaries/create"
          element={<CreateBeneficiary />}
        />
        {/* <Route
          path="wallet/transactions"
          element={<WalletTransactions />}
        /> */}
        <Route
          path="wallet/transactions/view"
          element={<ViewTransaction />}
        />
        <Route
          path="wallet/create-pin"
          element={<CreatePin />}
        />
        <Route
          path="wallet/send-money-bank"
          element={<SendMoneyBank />}
        />
        <Route
          path="wallet/send-money-wallet"
          element={<SendMoneyWallet />}
        />

        <Route
          path="wallet/earned-wage-access/overview"
          element={<EarnedWageAccess />}
        />
        <Route
          path="wallet/earned-wage-access/pin"
          element={<SetEWAPin />}
        />
        <Route
          path="wallet/earned-wage-access/transactions"
          element={<Transactions />}
        />
        <Route
          path="wallet/earned-wage-access/withdrawal"
          element={<Withdrawal />}
        />
      </Route>
      <Route
        path="/employee/dashboard"
        element={<NewResponsiveEmployeeLayout />}
      >
        <Route
          index
          // path="/employee/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="twofa-setup"
          element={<TwoFaSetupPage />}
        />
        <Route
          path="people"
          Component={React.lazy(
            () => import("../pages/employee-dashboard/people/EmployeesList")
          )}
        />
        <Route
          path="notifications"
          Component={React.lazy(() => import("../pages/notifications/"))}
        />
        {/* Profile */}
        <Route
          path="profile"
          element={<EmployeeProfile />}
        />
        <Route
          path="welcome"
          element={<EmployeeOnboarding />}
        />
        <Route
          path="job-and-pay"
          element={<JoyAndPay />}
        />
        <Route
          path="personal"
          element={<Personal />}
        />
        <Route
          path="org-chart"
          element={<OrganisationChart isForEmployee />}
        />
        <Route
          path="requests/all"
          element={<EmployeeAllRequest />}
        />
        <Route
          path="requests/leave-balances"
          element={<LeaveBalances />}
        />
        <Route
          path="requests/all/create-exit"
          element={<CreateExitRequest />}
        />
        <Route
          path="requests/all/create-leave"
          element={<CreateLeaveRequest />}
        />
        <Route
          path="requests/all/create-loan"
          element={<CreateLoanRequest />}
        />
        <Route
          path="requests/all/create-salaryadvance"
          element={<CreateSalaryAdvance />}
        />
        <Route
          path="requests/all/create-redeployment"
          element={<CreateRedeploymentRequest />}
        />
        <Route
          path="requests/all/create-document"
          element={<CreateDocumentRequest />}
        />
        <Route
          path="disciplinary/create-violation"
          element={<CreateViolationRequest />}
        />
        <Route
          path="document"
          element={<Document />}
        />
        <Route
          path="survey"
          element={<Survey />}
        />
        <Route
          path="survey/view"
          element={<ViewEmpSurvey />}
        />
        <Route
          path="benefits"
          element={<Benefits />}
        />
        <Route
          path="payables"
          element={<Payables />}
        />
        <Route
          path="payables/new"
          element={<CreateNewPayableRequest />}
        />
        <Route
          path="payslip"
          element={<Payslip />}
        />
        <Route
          path="payslip/view-payslip"
          element={<ViewPayslip />}
        />
        <Route
          path="payslip/download-payslip"
          element={<DownloadPayslip />}
        />
        <Route
          path="payslip/all-payslips"
          element={<ViewAllPayslip />}
        />
        {/* CROSS-BORDER PAYMENT  */}
        <Route
          path="wallet/cross-border/overview"
          element={<CrossBorderEmployee />}
        />
        <Route
          path="wallet/cross-border/local"
          element={<ViewLocalCurrency />}
        />
        <Route
          path="wallet/cross-border/transactions"
          element={<CrossBorderTransEmp />}
        />
        <Route
          path="wallet/cross-border/transfers"
          element={<CrossBorderTransfers />}
        />
        <Route
          path="wallet/cross-border/view"
          element={<ViewCurrencyEmp />}
        />
        <Route
          path="wallet/cross-border/swap-money"
          element={<SwapMoneyEmp type="employee" />}
        />
        <Route
          path="wallet/cross-border/send-money"
          element={<SendMoneyEmpCrossBorder />}
        />
        <Route
          path="wallet/cross-border/move-funds"
          element={<MoveFundsEmp />}
        />
        <Route
          path="wallet/overview"
          element={<Wallet />}
        />
        <Route
          path="wallet/kyc"
          element={<KYC />}
        />
        <Route
          path="wallet/beneficiaries"
          element={<Beneficiaries />}
        />
        <Route
          path="wallet/beneficiaries/create"
          element={<CreateBeneficiary />}
        />
        {/* <Route
          path="wallet/transactions"
          element={<WalletTransactions />}
        /> */}
        <Route
          path="wallet/transactions/view"
          element={<ViewTransaction />}
        />
        <Route
          path="wallet/create-pin"
          element={<CreatePin />}
        />
        <Route
          path="wallet/send-money-bank"
          element={<SendMoneyBank />}
        />
        <Route
          path="wallet/send-money-wallet"
          element={<SendMoneyWallet />}
        />

        <Route
          path="wallet/earned-wage-access/overview"
          element={<EarnedWageAccess />}
        />
        <Route
          path="wallet/earned-wage-access/pin"
          element={<SetEWAPin />}
        />
        <Route
          path="wallet/earned-wage-access/transactions"
          element={<Transactions />}
        />
        <Route
          path="wallet/earned-wage-access/withdrawal"
          element={<Withdrawal />}
        />
        <Route
          path="wallet/bill-payments"
          element={<BillPayments />}
        />
        <Route
          path="wallet/bill-payments/transactions"
          element={<BillPaymentsTransactions />}
        />
        {/* <Route
          path="wallet/bill-payments/transactions/details"
          element={<BillPaymentsTransactionDetails />}
        /> */}
        <Route
          path="wallet/bill-payments/buy"
          element={<Buy />}
        />
        <Route
          path="christmas-village"
          element={<ChristmasVillage />}
        />
        <Route
          path="assets"
          element={<Assets />}
        />
        <Route
          path="tasks"
          element={<Tasks />}
        />
        <Route
          path="tasks/:taskId"
          element={<ViewTask />}
        />
        <Route
          path="overview"
          element={<PerformanceOverview />}
        />

        <Route
          path="objectives"
          element={<PerformanceObjectives />}
        />
        <Route
          path="feedback"
          element={<Feedback />}
        />
        <Route
          path="feedback/send"
          element={<GiveFeedback />}
        />
        <Route
          path="feedback/request"
          element={<RequestFeedback />}
        />
        <Route
          path="conversations"
          element={<Conversation />}
        />
        <Route
          path="conversations/create"
          element={<CreateConversation />}
        />

        <Route
          path="reviews"
          element={<ViewReviewListing />}
        />
        <Route
          path="reviews/view"
          element={<ViewReviewDetailed />}
        />
        <Route
          path="reviews/view-employee"
          element={<ViewReviewsEmployee />}
        />
        <Route
          path="disciplinary"
          element={<Disciplinary />}
        />
        <Route
          path="disciplinary/violations/:violationId"
          element={<ViewViolationPage />}
        />
        <Route
          path="notes"
          element={<Notes />}
        />
      </Route>
    </>
  )
}

export default EmployeeDashboard
