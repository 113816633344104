import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useGet } from "utils/useFetch"
import { ISurveyData } from "../interface"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import PreviewQuestion from "../component/PreviewQuestion"
import { Spin } from "antd"
import { Button } from "components"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"

const PreviewSurvey = () => {
  const navigate = useNavigate()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const { isLoading, data } = useGet<ISurveyData>({
    url: `/admin/surveys/view/index?id=survey&x=${x}&s=`,
    enabled: !!x,
  })
  const buttonsTab = [
    {
      title: "Edit",
      icon: <ExclamationCircleIcon />,
      onclick: function () {
        navigate(`/dashboard/survey/edit?x=${x}`)
      },
    },
    ...(data && data?.survey?.questions.length
      ? [
          {
            title: "Assign",
            icon: <ExclamationCircleIcon />,
            onclick: function () {
              navigate(`/dashboard/survey/assign?x=${x}`)
            },
          },
        ]
      : []),
  ]
  return (
    <DashboardWrapper>
      <div className="">
        <div className="flex items-center justify-between mb-4">
          <h5 className=" text-padeBlack  text-base">
            Preview {data?.survey?.description}
          </h5>
          <div className="flex gap-2 items-center">
            {buttonsTab.map((tab, index) => (
              <Button
                key={index}
                title="Previous"
                color="secondary"
                className="border border-primary500 bg-white "
                onClick={tab?.onclick}
              >
                <span className="h-5 w-5">{tab?.icon}</span>
                <span>{tab.title}</span>
              </Button>
            ))}
          </div>
        </div>
        <div className=" bg-white px-4 py-6">
          {isLoading ? (
            <div className="flex justify-center items-center my-10">
              <Spin spinning={isLoading} />
            </div>
          ) : data && data?.survey.questions.length > 0 ? (
            <PreviewQuestion data={data?.survey?.questions} />
          ) : (
            <span className="flex justify-center items-center h-[100px]">
              No available survey question
            </span>
          )}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default PreviewSurvey
