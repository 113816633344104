import { Tag } from "antd"

type props = {
  status: string
  subTitle?: string
  percentage?: number | string
}

export const StatusBar: React.FC<props> = ({
  status,
  percentage,
  subTitle,
}) => {
  return (
    <div
      className={` px-1 sm:!px-[6px] w-[110px]  sm:!w-[120px] flex ${
        percentage == "" ? "justify-center" : "justify-between"
      }  items-center border gap-1  h-6   rounded-5px  text-neutral700  font-medium  bg-[#EBEDF0]
                        ${
                          status === "Achieved" || status === "Exceeded"
                            ? "border-success800 bg-success100  "
                            : ""
                        }
                       ${
                         status === "Not started " || status === "Not measured"
                           ? "border-neutral500  bg-[#EBEDF0]"
                           : ""
                       }
                        ${
                          status === "On track"
                            ? "border-[#E69702] bg-[#FEF9F3] "
                            : ""
                        }
                       ${
                         status === "Off track"
                           ? "border-danger500 bg-[#FEE8E8]"
                           : ""
                       }
                        `}
    >
      <span className="text-xs sm:text-[13px]  ">
        {status === "Not measured" ? "Not Measured" : status}
      </span>
      {status != "Not measured" && percentage !== 0 && (
        <span
          className={`text-xs sm:text-[13px] font-medium pt-1 pb-0.5 leading-none pr-1 rounded-[4px] flex items-center`}
        >
          {percentage}%{" "}
        </span>
      )}
    </div>
  )
}
