import { DashboardContainer } from "components"
import React, { useState, useMemo } from "react"
import { useGet } from "utils/useFetch"

import { Link, useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"

import { Dropdown, Select, Table } from "antd"
import { surveyTypes } from "../Survey"
import CustomInfoCard from "components/new-stuff/ui/CustomInfoCard"
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"
import DeleteSurvey from "../modals/DeleteSurvey"
import { ICustomSurveyData } from "../interface/templates"

const Templates: React.FC<{}> = () => {
  const myParam = useLocation().search
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const [showNewTemplate, setShowNewTemplate] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState("")
  const [activeTab, setActiveTab] = useState("Custom")
  const [typeId, setTypeId] = useState("1")
  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`admin/surveys/selectcustomtemplates?typeId=${typeId}`],
    })
  }
  const { isLoading: isCustomLoading, data: CustomTemplateData } =
    useGet<ICustomSurveyData>({
      url: `admin/surveys/selectcustomtemplates?typeId=${typeId}`,
    })
  const { isLoading: isDefaultLoading, data: DefaultTemplateData } =
    useGet<ICustomSurveyData>({
      url: `admin/surveys/selectdefaulttemplates?typeId=${typeId}`,
    })
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_reviewtemplate?id=${activeDeleteId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenDeleteConfirmation(false)
        invalidateQueries()

        {
          data.msg && toast.success(data.msg)
        }
      } else {
        setOpenDeleteConfirmation(false)
        {
          data.msg && toast.error(data.msg)
        }
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const data = activeTab === "Custom" ? CustomTemplateData : DefaultTemplateData
  console.log(data)

  const TableData =
    data?.data?.map((temp) => ({
      key: temp.survey_template_id,
      description: temp.description,
      status: {
        id: temp.survey_template_status_id,
        text: temp.survey_template_status,
      },
      action: {
        id: temp.survey_template_id,
        key: temp.survey_template_key,
        statusId: temp?.survey_template_status_id,
      },
    })) || []

  const TableColumns = useMemo(
    () =>
      [
        {
          title: "Description",
          dataIndex: "description",
          render: (description: string) => (
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
               `}
            >
              {description}
            </span>
          ),
        },

        {
          title: "Status",
          dataIndex: "status",
          render: (status: { text: string; id: string }) => (
            <span
              className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]
                    ${status.id === "2" ? "bg-success100 text-success800" : ""}
                    ${status.id === "1" ? "bg-neutral40 text-neutral500" : ""}
                    ${status.id === "3" ? "bg-[#FEE3B1] text-[#E69702]" : ""}

                    `}
            >
              {status.text}
            </span>
          ),
        },

        {
          title: "",
          dataIndex: "action",
          key: "action",
          render: (data: { key: string; id: number; statusId: string }) => (
            <Dropdown
              menu={{
                items: actionItems(data),
              }}
            >
              <button>
                <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
              </button>
            </Dropdown>
          ),
        },
      ] || [],
    []
  )
  //  three dots on each row
  const actionItems = (data: { key: string; id: number; statusId: string }) => {
    let arrayOptions: any = []
    {
      data?.statusId === "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "0",
            label: (
              <Link to={`/dashboard/survey/template/preview?x=${data?.key}`}>
                Preview
              </Link>
            ),
          },
        ])
    }
    {
      data?.statusId === "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: (
              <Link to={`/dashboard/survey/template?x=${data?.key}`}>Edit</Link>
            ),
          },
        ])
    }

    {
      data?.statusId !== "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "2",
            label: (
              <Link to={`/dashboard/survey/template?x=${data?.key}`}>
                Finish
              </Link>
            ),
          },
        ])
    }
    {
      data?.statusId &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "3",
            label: (
              <div
                onClick={() => {
                  handleOpenDeleteModal(String(data.id))
                }}
              >
                Delete
              </div>
            ),
          },
        ])
    }

    return arrayOptions
  }
  const handleClick = () => {
    if (showNewTemplate) {
      setShowNewTemplate(false)
    } else {
      setShowNewTemplate(true)
    }
  }
  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteConfirmation(true)
    setActiveDeleteId(String(id))
  }
  const handleDelete = () => {
    submitDelete()
  }

  return (
    <DashboardContainer>
      <div className="relative">
        <CustomInfoCard
          title={
            <div className="border-b flex gap-2">
              {["Custom", "Default"].map((tab, index) => (
                <button
                  key={index}
                  className={`relative py-2 px-3 text-neutral500 font-semibold font-avenir text-base ${
                    activeTab === tab &&
                    "border-primary500 border-b-2 !text-primary500"
                  }`}
                  onClick={() => setActiveTab(tab as string)}
                >
                  {tab}
                </button>
              ))}
            </div>
          }
          loading={isCustomLoading}
          actions={
            <Select
              defaultValue={typeId}
              style={{ width: 125 }}
              placeholder="Filter by"
              onChange={(value: string) => {
                setTypeId(value)
              }}
              options={surveyTypes.map((surv) => ({
                value: surv.survey_id,
                label: surv.survey_title,
              }))}
              className="font-avenir "
            />
          }
        >
          <div className=" mt-4 flex flex-col gap-4 px-6">
            <Table
              columns={TableColumns}
              dataSource={TableData}
              loading={isCustomLoading || isDefaultLoading}
              className="text-[#42526D] min-h-screen"
              // pagination={{
              //   total: surveyData?.total,
              //   defaultPageSize: 20,
              // }}
            />
          </div>
        </CustomInfoCard>
        <DeleteSurvey
          title="Delete Template"
          description="
          If you delete this template,  it would be removed from your list. Are you sure you want to continue?
          "
          url="/admin/surveys/remove_template"
          activeDeleteId={activeDeleteId}
          open={openDeleteConfirmation}
          onClose={() => {
            setOpenDeleteConfirmation(false)
            setActiveDeleteId("")
          }}
          invalidateQueries={invalidateQueries}
        />
      </div>
    </DashboardContainer>
  )
}
export default Templates
