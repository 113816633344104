import { Modal } from "antd"
import { useEffect, useState } from "react"
import handBagImg from "assets/illustrations/cvg/amico.png" // delete image after cvg
import { Button, H2, P1 } from "components"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

const CvgAlertModal = () => {
  const [showAlert, setShowAlert] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const itm = sessionStorage.getItem("hasSeenAlert")
    const isSeen = itm ? JSON.parse(itm) : "no"

    if (isSeen === "no") {
      setShowAlert(true)
    }
  }, [])

  const daysToGo = dayjs().endOf("month").diff(dayjs(), "day")

  if (!showAlert || daysToGo < 1) {
    return null
  }

  return (
    <Modal
      title={null}
      footer={null}
      wrapClassName="[&_.ant-modal-content]:!p-0"
      onCancel={() => {
        setShowAlert(false)
        sessionStorage.setItem("hasSeenAlert", JSON.stringify("yes"))
      }}
      destroyOnClose
      open={showAlert}
      maskClosable={false}
      width={800}
    >
      <div className="py-16 md:py-24 px-4 md:px-10 pb-4 ">
        <div className="flex flex-col md:flex-row md:gap-6 lg:gap-10 md:items-center">
          <div className="flex items-center justify-center md:justify-start shrink-0">
            <img
              src={handBagImg}
              alt=""
              width={292}
              height={292}
              className="w-[184px] h-[184px] md:w-[292px] md:h-[292px]"
            />
          </div>

          <div className="flex-col md:flex-row mt-8 md:mt-0 max-w-[280px] md:max-w-[415px] mx-auto md:mx-0">
            <P1 className="text-padeBlack font-avenir text-center md:text-start text-sm md:text-base">
              Christmas Village will be{" "}
              <span className="font-bold italic"> closing its doors</span> soon.
              Take advantage of the incredible{" "}
              <span className="font-bold italic">discounts</span> before it’s
              too late!
            </P1>
            <div className="bg-[#CCDBFD] mt-4 mb-10 rounded-md p-2">
              <H2 className="text-padeBlack font-avenir font-bold text-xl md:text-2xl text-center">
                {daysToGo} day{daysToGo > 1 ? "s" : ""} to go!
              </H2>
            </div>
            <div className="flex justify-center md:justify-start pb-6">
              <Button
                color="primary"
                onClick={() => {
                  setShowAlert(false)
                  sessionStorage.setItem("hasSeenAlert", JSON.stringify("yes"))
                  navigate("/employee/dashboard/christmas-village")
                }}
              >
                Proceed to Christmas Village
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CvgAlertModal
