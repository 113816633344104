import { Form, Select, Spin } from "antd"
import React, { useState } from "react"
import FormSectionWrapper from "pages/dashboard/extras/settings/FormSectionWrapper"
import { Button, DashboardContainer } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"

import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"

import AntDesignSelect from "components/antd/AntDesignSelect"

import { IEmployeesListing } from "stores/payroll/Changepayroll/CreateEmployees/getEmployees"
import { ISurveyData, ITemplateBody } from "../interface"
import getIdFromKey from "utils/getIdFromKey"

const assignSurvey: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search

  const x = new URLSearchParams(myParam).get("x")
  const employeeTypeId = Form.useWatch("type", form)
  console.log(employeeTypeId)

  const [activeKey, setActiveKey] = useState<string[]>(["1"])

  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([])

  const onFinish = (values: Values) => {
    const body = selectedEmployees?.map((emp) => {
      return {
        person_id: emp,
      }
    })
    console.log(body)

    submit(body)
  }
  const {
    isLoading: isSurveyLoading,
    data: surveyData,
    isFetching: isFetchingEmpData,
  } = useGet<ISurveyData>({
    url: `/admin/surveys/view/index?id=customize&x=${x}&s=`,
    enabled: !!x,
  })

  // ASSIGN SURVEY

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: { person_id: string }[]) => {
      const res = await axiosInstance.post(
        `/admin/surveys/assign?id=${getIdFromKey(x || " ")}`,
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey`)

        data.msg && toast.success(data.msg)
      }
    },
  })

  // GET EMPLOYEES
  const { isLoading: employeeListLoading, data: employeeList } = useGet<
    IEmployeesListing[]
  >({
    url: `/admin/employees/get?active=true`,
  })

  const employeedocOwners = [
    {
      title: "All employees",
      id: "1",
    },
    {
      title: "Specific employees",
      id: "2",
    },
  ]
  const handleChangeEmployee = (values: any) => {
    const personIds = values.map((per: string) => {
      return String(per)
    })
    setSelectedEmployees(personIds)
  }
  return (
    <DashboardContainer>
      <div className="max-w-[1024px]   rounded-md space-y-[20px]">
        {isSurveyLoading ? (
          <div className="h-[100px] flex justify-center items-center">
            <Spin spinning />
          </div>
        ) : (
          <FormSectionWrapper
            name={`Assign "${surveyData?.survey?.description}" ${surveyData?.survey?.survey_type} Survey`}
            title={`Assign "${surveyData?.survey?.description}" ${surveyData?.survey?.survey_type} Survey`}
            subtitle="Choose employees you want to assign this survey to"
            // subtitle="Choose a template to auto-populate the survey questions. You can edit the questions later. Don't choose a template if you want to enter all the questions yourself."
            isActive={activeKey.includes("1")}
            layout="vertical"
            form={form}
            onFinish={onFinish}
            style={{ maxWidth: 1024 }}
            autoComplete="off"
            footerBtns={
              <div className="flex gap-3  items-center justify-end">
                <Button
                  color="secondary"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  submitting={isLoading}
                >
                  Submit
                </Button>
              </div>
            }
            className="font-avenir"
          >
            <div className=" px-4 md:px-6 xl:px-[60px] pb-4">
              <AntDesignSelect
                name="type"
                label="Employees"
                isLoading={employeeListLoading}
                options={employeedocOwners?.map((emp) => ({
                  label: emp.title,
                  value: emp.id,
                }))}
                onChange={(value) => {
                  console.log(value)
                  if (value == 1) {
                    const personIds = employeeList?.map((per: any) =>
                      String(per?.id)
                    )

                    if (personIds) {
                      setSelectedEmployees(personIds)
                    }
                  }
                }}
                placeholder="Select Employee"
              />

              {employeeTypeId === "2" && (
                <Form.Item
                  label="Select specific employees"
                  name="employees"
                >
                  <Select
                    mode="multiple"
                    loading={employeeListLoading}
                    onChange={handleChangeEmployee}
                    options={
                      employeeListLoading
                        ? []
                        : employeeList?.map((emp) => ({
                            label: emp.text,
                            value: emp.id,
                          }))
                    }
                    placeholder="Select Employee"
                    optionLabelProp="label"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      if (option) {
                        const value = (option?.label as string) || ""
                        return (
                          value?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                          0
                        )
                      } else {
                        return false
                      }
                    }}
                  />
                </Form.Item>
              )}
            </div>
          </FormSectionWrapper>
        )}
      </div>
    </DashboardContainer>
  )
}
export default assignSurvey
interface Values {
  title: string
  description: string
  type: string
  template: string
}
