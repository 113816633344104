import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useGet } from "utils/useFetch"
import { ISurveyData } from "../interface"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import PreviewQuestion from "../component/PreviewQuestion"
import { Spin } from "antd"
import { Button } from "components"
import { ISurveyTemplateData } from "../interface/templates"

const PreviewTemplate = () => {
  const navigate = useNavigate()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const { isLoading, data } = useGet<ISurveyTemplateData>({
    url: `/admin/surveys/view/index?id=template&x=${x}&s=`,
    enabled: !!x,
  })
  console.log(data)
  return (
    <DashboardWrapper>
      <div className="">
        <div className="flex items-center justify-between mb-4">
          <h5 className=" text-padeBlack  text-base">
            Preview {data?.template?.description}
          </h5>
          <Button
            color="primary"
            onClick={() => navigate(`/dashboard/survey/template?x=${x}`)}
          >
            Edit
          </Button>
        </div>
        <div className=" bg-white px-4 py-6">
          {isLoading ? (
            <Spin spinning={isLoading} />
          ) : data ? (
            <PreviewQuestion data={data?.template?.questions} />
          ) : (
            <span>No available Question</span>
          )}
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default PreviewTemplate
