import { Collapse, DatePicker, Drawer, Select, Spin } from "antd"
import React, { useState } from "react"
import type { CollapseProps } from "antd"

// import Measurements from "../../../employee-dashboard/performance/initiatives/Measurements"

import { useGet } from "utils/useFetch"
import { IReviewKREmplData } from "../interface/viewemployee"
import { CloseOutlined } from "@ant-design/icons"
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import Feedback from "pages/employee-dashboard/performance/overview/component/Feedback"
import { StatusBar } from "components/custom/StatusBar"
const { Panel } = Collapse

type measurementprops = {
  ReviewEntityKey: string
  open: boolean
  onClose: () => void
}
const ReviewViewKeyResults: React.FC<measurementprops> = ({
  ReviewEntityKey,
  open,
  onClose,
}) => {
  const { RangePicker } = DatePicker

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [dateType, setDateType] = useState("1")
  const [showCustomDate, setShowCustomDate] = useState(false)
  const [activeKey, setActiveKey] = useState<string | string[]>(["1"])

  const {
    isLoading,
    data: personInfo,
    refetch,
  } = useGet<IReviewKREmplData>({
    url: `/performance/getperformancedata?id=${ReviewEntityKey}&x=${dateType}&s=${startDate}&r=${endDate}`,
  })

  // const {
  //   isLoading: initiativeLoading,
  //   data: initiativesData,
  //   refetch,
  // } = useGet<any>({
  //   url: `admin/performance/selectanotheremployeeobjectives?person_id=${personForKR}&period_id=${periodID}&for_employee=${true}`,
  //   // enabled: !!selectedPersonId,
  // })

  // const totalCompletedMeasurement = initiativesData?.data.filter(
  //   (item: any) =>
  //     item.employee_objective_status_id == "4" ||
  //     item.employee_objective_status_id == "5"
  // )

  // const Panels: Items[] = [
  //   {
  //     key: "1",
  //     header: (
  //       <div className=" font-avenir">
  //         <h6 className=" text-neutral700 text-base font-extrabold">
  //           Measured Initiatives
  //         </h6>
  //         <p className=" text-neutral400 text-sm font-light">
  //           {totalCompletedMeasurement?.length || 0}/
  //           {initiativesData?.data.length} Completed
  //         </p>
  //       </div>
  //     ),
  //     children: (

  //     ),
  //   },
  // ]

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Key Results",
      children: (
        <div className=" bg-[#F3F5FB]">
          {personInfo?.employeeObjectives.map((item) => (
            <div className="border-b p-4">
              <p className="text-sm font-circular text-[#42526D] capitalize">
                {item?.key_result}
              </p>
              <div className="mt-2 font-circular flex items-center justify-between text-xs text-[#7A8699]">
                <p>{item?.owner_nick_name}</p>

                <p className="flex items-center">
                  <CalendarIcon className="w-4 h-4 text-[#7A8699]" />
                  <span>Due on:{item?.due_date_display}</span>
                </p>
                <StatusBar
                  percentage={
                    item?.employee_objective_status == "Not measured" ||
                    !item?.summary
                      ? 0
                      : Number(item?.summary)
                  }
                  status={item?.employee_objective_status}
                />
              </div>
            </div>
          ))}
        </div>
      ),
    },
    {
      key: "2",
      label: "Feedback",
      children: (
        <div>
          {personInfo?.employeeFeedback?.length ? (
            personInfo?.employeeFeedback.map((item, index) => (
              <div className="bg-[#F3F5FB]">
                <Feedback
                  key={index}
                  item={item}
                />
              </div>
            ))
          ) : (
            <p className="text-center my-10">No available Feedback</p>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "Conversation",
      children: (
        <div>
          {personInfo?.employeeConversations?.length ? (
            personInfo?.employeeConversations.map((item, index) => (
              <div className="bg-[#F3F5FB]">
                conversation {index + 1}
                {/* <Feedback
                  key={index}
                  item={item}
                /> */}
              </div>
            ))
          ) : (
            <p className="text-center my-10">No available conversation</p>
          )}
        </div>
      ),
    },
  ]
  const onChange = (key: string | string[]) => {
    setActiveKey(key)
  }
  const panelStyle: React.CSSProperties = {
    marginBottom: 24, // Space between panels
    // background: "#000000",
    borderRadius: "4px",
    border: "1px solid #B3B9C4",
  }
  return (
    <Drawer
      width={683}
      title={
        <span className=" text-padeBlack font-circular text-xl">
          Key Results
        </span>
      }
      open={open}
      closeIcon={false}
      extra={
        <button onClick={onClose}>
          <CloseOutlined className="h-6 w-6" />
        </button>
      }
    >
      <div className=" font-avenir flex flex-col gap-4">
        {isLoading ? (
          <Spin />
        ) : personInfo ? (
          // <DropdownCard
          //   rootClassName="perfCollapse h-auto  bg-white"
          //   items={Panels}
          // />
          <div>
            <div className="flex mb-9">
              <Select
                defaultValue={dateType}
                onChange={(value) => {
                  if (value === "-1") {
                    setShowCustomDate(true)
                    setDateType(value)
                  } else {
                    setDateType(value)
                  }
                }}
                style={{ width: 200 }}
                placeholder="Select Date Range"
                options={[
                  {
                    value: "1",
                    label: "Last 3 Months",
                  },
                  {
                    value: "2",
                    label: "Last 6 Months",
                  },
                  {
                    value: "3",
                    label: "Last 1 Year",
                  },
                  {
                    value: "-1",
                    label: "Custom",
                  },
                ]}
              />

              {showCustomDate && (
                <RangePicker
                  format="DD/MM/YYYY"
                  onChange={(dates) => {
                    if (dates && dates.length === 2) {
                      const start = dates[0]?.format("DD/MM/YYYY") ?? ""
                      const end = dates[1]?.format("DD/MM/YYYY") ?? ""
                      setStartDate(start)
                      setEndDate(end)
                      refetch()
                    }
                  }}
                  placeholder={["Start Date", "End Date"]}
                  style={{ marginRight: 8 }}
                />
              )}
            </div>

            <Collapse
              defaultActiveKey={["1"]}
              onChange={onChange}
              bordered={false}
            >
              {items?.map((it: any, index: any) => (
                <Collapse.Panel
                  showArrow={false}
                  style={panelStyle}
                  header={
                    <div className="flex items-center justify-between w-full">
                      <span className=" font-extrabold text-[#243757] text-sm font-avenir">
                        {it.label}
                      </span>
                      <span
                        className={`transition-transform ${
                          activeKey.includes(it.key) ? "rotate-180" : ""
                        }`}
                      >
                        <ChevronDownIcon className="h-4 w-4" />
                      </span>
                    </div>
                  }
                  key={it.key}
                >
                  {it?.children}
                </Collapse.Panel>
              ))}
            </Collapse>

            {/* <Measurements
            completed={totalCompletedMeasurement?.length}
            total={initiativesData?.data.length}
            keyResultDetails={initiativesData?.data || []}
            refetchKeyData={() => refetch()}
            showCompleted={false}
            isProgressUpdateAllowed={false}
            isKRLocked={false}
            permissionGranted={true}
            myInitiativeSection={true}
            showUpdateProgressBtn={false}
          /> */}
          </div>
        ) : (
          <div>No available Initiative</div>
        )}
      </div>
    </Drawer>
  )
}

export default ReviewViewKeyResults
