import { Form, Select } from "antd"
import React, { useState } from "react"
import { CustomInput } from "pages/dashboard/documents/custom-form"
import FormSectionWrapper from "pages/dashboard/extras/settings/FormSectionWrapper"
import { Button, DashboardContainer } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"

import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"

import AntDesignSelect from "components/antd/AntDesignSelect"

import { IEmployeesListing } from "stores/payroll/Changepayroll/CreateEmployees/getEmployees"
import { ICustomSurveyData } from "../interface/templates"
import { ITemplateBody } from "../interface"

const CreateSurvey: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search

  const [selectedEmployeeType, setSelectedEmployeeType] = useState("")

  const selectedTypeId = Form.useWatch("type", form)

  const [activeKey, setActiveKey] = useState<string[]>(["1"])
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([])
  const reqBody = {
    q: "",
    page: "",
  }

  const { isLoading: isTemplateListingLoading, data: templateListing } =
    useGet<ICustomSurveyData>({
      url: `admin/surveys/selectcustomtemplates?typeId=${selectedTypeId}`,
      enabled: !!selectedTypeId,
    })

  const onFinish = (values: Values) => {
    const { title, description, type, template } = values
    const body = {
      description: title,
      survey_id: "-1",
      survey_type_id: type,
      survey_template_id: template ?? "-1",
      default_template: "no",
    }
    console.log(body)

    submit(body)
  }

  // ADD SURVEY

  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/add`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey/edit?x=${data?.data}`)

        data.msg && toast.success(data.msg)
      }
    },
  })

  // GET EMPLOYEES
  const { isLoading: employeeListLoading, data: employeeList } = useGet<
    IEmployeesListing[]
  >({
    url: `/admin/employees/get?active=true`,
  })

  const surveyTypes = [
    { survey_title: "General", survey_id: "1" },
    { survey_title: "Confirmation", survey_id: "2" },
    { survey_title: "Exit", survey_id: "3" },
  ]

  const employeedocOwners = [
    {
      title: "All employees",
      id: "1",
    },
    {
      title: "Specific employees",
      id: "2",
    },
  ]
  const handleChangeEmployee = (values: any) => {
    const personIds = values.map((per: string) => {
      let person = per.split("-")
      let personId = person[1]
      let personName = person[0]
      return personId
    })

    setSelectedEmployees(personIds)
  }
  return (
    <DashboardContainer>
      <div className="max-w-[1024px]   rounded-md space-y-[20px]">
        <FormSectionWrapper
          name="Create Survey"
          title="Create Survey"
          subtitle="Once it has a title, you can continue setting it up "
          // subtitle="Choose a template to auto-populate the survey questions. You can edit the questions later. Don't choose a template if you want to enter all the questions yourself."
          isActive={activeKey.includes("1")}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          style={{ maxWidth: 1024 }}
          autoComplete="off"
          footerBtns={
            <div className="flex gap-3 justify-end">
              <Button
                color="secondary"
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                submitting={isLoading}
              >
                Submit
              </Button>
            </div>
          }
          className="font-avenir"
        >
          <div className="grid grid-cols-2 gap-x-6  px-4 md:px-6 xl:px-[60px] pb-4">
            <div>
              <CustomInput
                label="Survey title"
                name="title"
                required
                className="w-full"
                placeholder="Enter survey title"
              />
              {errorMessage !== "" && errorMessage}
            </div>
            <Form.Item
              name="type"
              label={
                <span className="font-circular text-neutral300 text-sm  ">
                  Survey type
                </span>
              }
              required={true}
            >
              <Select
                showSearch
                placeholder="Please select"
                options={
                  surveyTypes &&
                  surveyTypes?.map((surv) => ({
                    label: surv.survey_title,
                    value: surv.survey_id,
                  }))
                }
                optionLabelProp="label"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    console.log(option)

                    const value = (option?.label as string) || ""
                    return (
                      value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    )
                  } else {
                    return false
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="template"
              label={
                <span className="font-circular text-neutral300 text-sm  ">
                  Templates
                </span>
              }
              required={true}
            >
              <Select
                showSearch
                placeholder="Please select"
                loading={isTemplateListingLoading}
                options={
                  isTemplateListingLoading
                    ? []
                    : templateListing &&
                      templateListing?.data?.map((temp) => ({
                        label: temp.description,
                        value: temp?.survey_template_id,
                      }))
                }
                optionLabelProp="label"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  if (option) {
                    const value = (option?.label as string) || ""
                    return (
                      value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    )
                  } else {
                    return false
                  }
                }}
              />
            </Form.Item>
          </div>
        </FormSectionWrapper>
      </div>
    </DashboardContainer>
  )
}
export default CreateSurvey
interface Values {
  title: string
  description: string
  type: string
  template: string
}
