import { useQuery } from "@tanstack/react-query"
import CustomCard from "components/new-stuff/ui/CustomCard"
import { formatCurrencyNoSymbol } from "utils/currency"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { axiosInstance } from "utils/request"

import { Spin } from "antd"
import { availableFlags } from "../sampleData"

const Rates = () => {
  const { isLoading: isRatesLoading, data: ratesData } = useQuery<any>({
    queryKey: [`${walletBaseUrl}/v1/wallets/conversion-rate-combination`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/conversion-rate-combination`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },

    // keepPreviousData: true,
    refetchOnWindowFocus: false,
    cacheTime: 24 * 60 * 60 * 1000,
  })
  const formattedRates = ratesData?.data
    .map((rate: any) => {
      const destinationFlag = availableFlags?.find(
        (flag) => flag.code === rate.destinationCurrency
      )
      const sourceFlag = availableFlags.find(
        (flag) => flag.code === rate.sourceCurrency
      )

      if (destinationFlag && sourceFlag) {
        return {
          toBeConverted: destinationFlag?.code,
          toBeConvertedFlag: destinationFlag.flag,
          converted: rate.sourceCurrency,
          convertedFlag: sourceFlag.flag,
          rate: rate.rate,
          symbol: sourceFlag.symbol,
        }
      }

      return null
    })
    .filter((item: any) => item !== null)

  return (
    <Spin spinning={isRatesLoading}>
      <CustomCard
        title="Today’s Rate"
        rootClassName="h-full !px-0"
        headerClassName="px-4 border-b border-[#F0F2F5]"
      >
        <div className=" flex px-4 py-6 items-center  justify-start  gap-[38px] overflow-x-scroll  scrollbar-hide">
          {formattedRates && formattedRates?.length > 0 ? (
            formattedRates?.map((item: any, index: any) => (
              <div
                key={index}
                className=" flex  gap-2"
              >
                <div className=" flex -gap-2 items-center">
                  <span className=" h-[24px] w-[24px]  ">
                    <img
                      src={item?.convertedFlag}
                      alt=""
                      className=" h-full w-full "
                    />
                  </span>
                  <span className=" h-[24px] w-[24px]  ">
                    <img
                      src={item?.toBeConvertedFlag}
                      alt=""
                      className=" h-full w-full "
                    />
                  </span>
                </div>

                <div className=" w-[130px] ">
                  <p className="text-bluesubtext font-bold font-circular leading-tight block">
                    {` ${item?.converted} > ${item?.toBeConverted}`}
                  </p>
                  <p className="mt-1 text-padeBlack font-bold text-sm">
                    {formatCurrencyNoSymbol(Number(item?.rate), {
                      minimumFractionDigits: 7,
                      maximumFractionDigits: 7,
                    })}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full h-full text-center">
              Available rates will appear here
            </div>
          )}
        </div>
      </CustomCard>
    </Spin>
  )
}

export default Rates
