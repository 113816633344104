import { IPaymentDetail } from "pages/dashboard/wallets/interface"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { formatCurrencyNoSymbol } from "utils/currency"
import { useGet } from "utils/useFetch"
import TotalEmployee from "assets/images/total_employee.svg"
import TotalSalary from "assets/images/total_salary.svg"
import EmployeeLeave from "assets/images/employee_leave.svg"
import { PayoutCard } from "../Component/PayoutCard"
import { Dropdown, Input, Spin, Tag, Tooltip } from "antd"
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { useAppNotificationStore, useAuthStore } from "stores"
import { Button } from "components"
import getIdFromKey from "utils/getIdFromKey"
import {
  ICurrencyInfo,
  IPaymentScheduleItem,
  IPayoutsResponse,
} from "./interface/payouts"
import ProcessPayment from "./payouts/ProcessPayment"
import ViewPayoutModal from "../modals/ViewPayoutsModal"
import ReversePayment from "../modals/ReversePayment"
import UnreversePayment from "../modals/UnreversePayment"
import usePermissions from "hooks/usePermissions"
import RestorePayment from "../modals/RestorePayment"
import DeletePayment from "../modals/DeletePayment"
import RefreshPayment from "../modals/RefreshPayment"
import RetryPayment from "../modals/RetryPayment"
import UpdatePayment from "../modals/UpdatePayment"
import ResetPayment from "../modals/ResetPayment"
import ResponsiveActinBtn from "components/new-stuff/ui/ResponsiveActinBtn"
import SelectPayment from "../modals/SelectPayment"
import DeselectPayment from "../modals/DeselectPayment"
import DownloadPayment from "./payouts/DownloadPayment"
import { SelectedTable } from "../SelectedTable"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { antIcon } from "pages/dashboard/dashboard/Dashboard"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"
import { CSVLink } from "react-csv"
import ViewTransferDetails from "pages/employee-dashboard/wallet/wallet/crossBorder/transfers/ViewTransferDetails"
const ViewCurrency = ({
  activeCurrency,
  currencies,
}: {
  activeCurrency: ICurrencyInfo | null
  currencies: ICurrencies[] | undefined
}) => {
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const { user } = useAuthStore()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const [canProcess, canDelete] = usePermissions({
    allowedPermissions: [
      "can_process_pay_schedules",
      "can_delete_pay_schedules",
    ],
  })
  // USESTATES
  const [type, setType] = useState(0)
  const [search, setSearch] = useState("")
  const [processSelected, setProcessSelected] = useState(false)
  const [active, setActive] = useState<IPaymentScheduleItem | null>(null)
  const [paymentId, setPaymentId] = useState("")
  const [allSelectedRowSum, setAllSelectedRowSum] = useState(0)
  // ACTION STATES
  const [showViewPayout, setShowViewPayout] = useState(false)

  const [openSelectAllConfirmation, setOpenSelectAllConfirmation] =
    useState(false)
  const [openDeselectAllConfirmation, setOpenDeselectAllConfirmation] =
    useState(false)
  const [openReverseConfirmation, setOpenReverseConfirmation] = useState(false)
  const [openUnreverseConfirmation, setOpenUnreverseConfirmation] =
    useState(false)
  const [openResetConfirmation, setOpenResetConfirmation] = useState(false)
  const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false)
  const [openRefreshConfirmation, setOpenRefreshConfirmation] = useState(false)
  const [openRetryConfirmation, setOpenRetryConfirmation] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [openRestoreConfirmation, setOpenRestoreConfirmation] = useState(false)
  const [openDownloadConfirmation, setOpenDownloadConfirmation] =
    useState(false)
  const [activeReference, setActiveReference] = useState("")
  const [showDetails, setShowDetails] = useState(false)
  const [filteredData, setFilteredData] = useState<any[]>([])
  // END OF ACTION STATES

  const {
    isLoading: isPayoutLoading,
    data: payoutData,
    refetch: refetchPayout,
    isFetching,
  } = useGet<IPayoutsResponse>({
    url: `admin/payment_schedules/selectpaymentscheduleitems_payout?id=${getIdFromKey(
      x || ""
    )}&x=${activeCurrency?.currency_code}`,
    enabled: activeCurrency !== null ? true : false,
    onSuccess: (data: IPayoutsResponse) => {
      if (data.data.length > 0) {
        setFilteredData(data.data)
      } else {
        setFilteredData([])
      }
    },
  })

  const {
    isLoading: isPaymentLoading,
    data,
    refetch,
  } = useGet<IPaymentDetail>({
    url: `admin/payment_schedules/view/index?id=detail&x=${x}&s=${activeCurrency?.currency_code}`,
    enabled: activeCurrency !== null ? true : false,
  })
  const invalidateQueries = () => {
    setPaymentId(getIdFromKey(x || ""))
    refetchPayout()
    refetch()
  }
  const {
    isLoading: isSubmittingDeselectionLoading,
    mutate: submitDeselection,
  } = useMutation({
    mutationFn: async (allSelectedIds: any) => {
      const res = await axiosInstance.post(
        `/admin/payment_schedules/update_payment_schedule_items?action=deselect&currency_code=${activeCurrency?.currency_code}`,
        allSelectedIds
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        invalidateQueries()
        mixPanelEvent("pay-schedule-payout-deselect-success")
      } else {
        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      data.msg && toast.error(data.msg)
    },
  })
  const { isLoading: isSubmittingLoading, mutate: submitSelection } =
    useMutation({
      mutationFn: async (allSelectedIds: any) => {
        const res = await axiosInstance.post(
          `/admin/payment_schedules/update_payment_schedule_items?action=select&currency_code=${activeCurrency?.currency_code}`,
          allSelectedIds
        )
        return res.data
      },
      onSuccess: (data) => {
        // process()
        if (data.status !== "error") {
          data.msg && toast.success(data.msg)

          mixPanelEvent("pay-schedule-payout-select-success")
          invalidateQueries()
        } else {
          data.msg && toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        data.msg && toast.error(data.msg)
      },
    })

  const cards = [
    {
      title: "Schedule Amount ",
      value: data?.amount ? formatCurrencyNoSymbol(Number(data?.amount)) : 0,
      icon: TotalEmployee,
      id: "payment-schedule-amount",
    },
    {
      title: "Payout Amount",
      value: data?.processed_amount
        ? formatCurrencyNoSymbol(Number(data?.processed_amount))
        : 0,
      icon: TotalSalary,
      id: "payment-amount-processed",
    },
    {
      title: "Pending Payout",
      value: data?.process_amount
        ? formatCurrencyNoSymbol(Number(data?.process_amount))
        : 0,
      icon: EmployeeLeave,
      id: "payment-amount-to-process",
    },
    {
      typeId: 1,
      title: `Bank Account (${data?.bank_count ? data?.bank_count : 0})`,
      value: data?.bank_amount
        ? formatCurrencyNoSymbol(Number(data?.bank_amount))
        : 0,
      icon: EmployeeLeave,
      id: "payment-bank-account",
      handleClick: () => {
        if (type == 1) {
          setType(0)
        } else {
          setType(1)
        }
      },
    },

    {
      typeId: 2,
      title: `Custom Wallet (${data?.wallet_count ? data?.wallet_count : 0})`,
      value: data?.wallet_amount
        ? formatCurrencyNoSymbol(Number(data?.wallet_amount))
        : 0,
      icon: TotalEmployee,
      id: "payment-custom-wallet",
      handleClick: () => {
        if (type == 2) {
          setType(0)
        } else {
          setType(2)
        }
      },
    },
  ]
  const uniqueBankNames = [
    ...new Set(payoutData?.data?.map((pay: any) => pay.bank_name)),
  ]
  const uniqueStatusNames = [
    ...new Set(
      payoutData?.data?.map((pay: any) => pay.payment_schedule_item_status)
    ),
  ]
  const bankFilters = uniqueBankNames?.map((bankName) => ({
    text: bankName,
    value: bankName,
  }))
  const statusFilters = uniqueStatusNames?.map((status) => ({
    text: status,
    value: status,
  }))
  const TableData =
    payoutData?.data
      ?.filter((rev: any) => {
        const searchString = `${rev.full_name || ""} ${
          rev.beneficiary_name || ""
        } ${rev.reference_number || ""}`

        return searchString.toLowerCase().includes(search?.toLowerCase())
      })

      ?.filter((payment: any) => {
        if (type !== 0) {
          return payment.account_class_id === type //
        }
        return true
      })
      ?.map((record, index) => {
        // const lastKey = Object.keys(record?.paymentProviderResponse ?? {}).pop()
        // const lastObject = lastKey
        //   ? record?.paymentProviderResponse?.[lastKey]
        //   : undefined
        // const transactionDescription =
        //   lastObject?.eventData?.transactionDescription ??
        //   lastObject?.data?.complete_message ??
        //   record?.statusMessage
        return {
          key: record.payment_schedule_item_id,
          flag:
            currencies?.find((item) => item.code === record.currency_code)
              ?.flagUrl || "",
          ...record,
        }
      }) || []
  const uniqueCurrencies = [
    ...new Set(payoutData?.data?.map((pay: any) => pay.currency_code)),
  ]

  const currencyFilters = uniqueCurrencies?.map((bankName) => ({
    text: bankName,
    value: bankName,
  }))
  const TableColumns =
    [
      {
        title: "Beneficiary",
        dataIndex: "beneficiary",
        render: (_: any, record: IPaymentScheduleItem) => (
          <div className="flex items-center gap-2">
            <div className="flex flex-col">
              <span
                className={`font-circular text-sm  text-padeBlack

                ${
                  record?.payment_schedule_item_status_id === "1" &&
                  "text-success800"
                }
                  `}
              >
                {Number(record?.payment_schedule_item_type_id) > 1
                  ? record?.beneficiary_name || ""
                  : record?.full_name || record?.beneficiary_name || ""}
              </span>
              {Number(record.payment_schedule_item_type_id) > 1 && (
                <Tag
                  color="processing"
                  className="w-[115px] text-xs font-medium"
                >
                  {record.payment_schedule_item_type_id == "3"
                    ? "Pension Schedule"
                    : record.payment_schedule_item_type_id == "4"
                    ? "NHF Schedule"
                    : record.payment_schedule_item_type_id == "2"
                    ? "Tax Schedule"
                    : record.payment_schedule_item_type_id == "8"
                    ? "EWA Repayment"
                    : record.payment_schedule_item_type_id == "9"
                    ? "NSITF Schedule"
                    : ""}
                </Tag>
              )}
            </div>
          </div>
        ),
      },
      {
        title: "Account",
        dataIndex: "bank",
        width: "25%",
        filters: bankFilters,
        onFilter: (value: boolean | Key, record: IPaymentScheduleItem) => {
          const filterValue = String(value)
          return record?.bank_name === filterValue
        },

        render: (_: any, record: IPaymentScheduleItem) =>
          record?.source_currency_code === "USD" && record?.account_number ? (
            <div className="flex flex-col">
              <span
                className={`font-circular flex flex-wrap text-sm text-padeBlack`}
              >
                {record?.routing_code}
                {record?.routing_type ? `(${record?.routing_type})` : "-"}
              </span>
              <span
                className={`font-circular flex flex-wrap text-xs text-padeBlack`}
              >
                {record.account_number}
              </span>
            </div>
          ) : record?.currency_code === "NGN" && record?.bank_name ? (
            <div className="flex flex-col">
              <span
                className={`font-circular flex flex-wrap text-sm text-padeBlack`}
              >
                {record?.bank_name}
              </span>
              <span
                className={`font-circular flex flex-wrap text-xs text-[#0c1b3d80]`}
              >
                {record.account_number}
              </span>
            </div>
          ) : record?.currency_code !== "NGN" && record?.account_number ? (
            <div className="flex flex-col">
              <span
                className={`font-circular flex flex-wrap text-sm text-padeBlack`}
              >
                {record?.routing_code}
                {record?.routing_type ? `(${record?.routing_type})` : "-"}
              </span>
              <span
                className={`font-circular flex flex-wrap text-xs text-padeBlack`}
              >
                {record.account_number}
              </span>
            </div>
          ) : (
            <div className="flex flex-col">
              <Tooltip title="Employee is missing details required to run payroll. ">
                <ExclamationTriangleIcon className="h-6 w-6 text-danger500" />
              </Tooltip>
            </div>
          ),
      },
      {
        title: "Currency",
        dataIndex: "currency",
        filters: currencyFilters,
        onFilter: (value: any, record: any) => {
          return record?.currency_code === value
        },
        render: (_: any, record: any) => (
          <div className="flex items-center gap-2">
            <div>
              <img
                src={record?.flag}
                alt=""
                className="h-6 w-6"
              />
            </div>
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.currency_code}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        render: (_: any, record: IPaymentScheduleItem) => (
          <div className="flex flex-col">
            <span
              className={`font-circular text-sm  text-padeBlack
                ${
                  record.payment_schedule_item_status_id == "1" &&
                  "text-success800"
                }
                  `}
            >
              {/* {record?.currency_symbol ?? ( */}
              {activeCurrency?.currency_symbol ?? (
                <ExclamationTriangleIcon className=" h-6 w-6 text-danger500" />
              )}
              {formatCurrencyNoSymbol(Number(record?.amount))}{" "}
            </span>
            {Number(record.processing_fee) > 0 ? (
              <Tag
                color="processing"
                className=" text-xs font-medium"
              >
                Fee: {activeCurrency?.currency_symbol}
                {formatCurrencyNoSymbol(Number(record.processing_fee))}
              </Tag>
            ) : (
              ""
            )}
            {!record?.is_active && (
              <Tag
                color="red"
                className=" text-xs font-medium"
              >
                Deleted
              </Tag>
            )}
          </div>
        ),
      },

      {
        title: "Payment Ref.No",
        dataIndex: "ref",

        render: (_: any, record: IPaymentScheduleItem) => (
          <div>
            <span
              className={`font-circular flex flex-wrap text-sm text-padeBlack`}
            >
              {record?.reference_number ?? "-"}
            </span>
            {record?.payment_method && <Tag>{record?.payment_method}</Tag>}
          </div>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        filters: statusFilters,
        onFilter: (value: boolean | Key, record: IPaymentScheduleItem) => {
          const filterValue = String(value)
          return record?.payment_schedule_item_status === filterValue
        },
        render: (_: any, record: IPaymentScheduleItem) => (
          <div className="">
            <div className="flex items-center gap-1">
              <Tag
                color={
                  record.payment_schedule_item_status_id === "1"
                    ? "green"
                    : record.payment_schedule_item_status_id === "2"
                    ? "orange"
                    : record.payment_schedule_item_status_id === "3"
                    ? "red"
                    : record.payment_schedule_item_status_id === "4"
                    ? "red"
                    : "default"
                }
              >
                {record?.payment_schedule_item_status}
              </Tag>
              {record?.response_description &&
                record.payment_schedule_item_status_id === "4" && (
                  <Tooltip title={record.response_description}>
                    <InformationCircleIcon className="w-4 h-4" />
                  </Tooltip>
                )}
            </div>
          </div>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_: any, record: IPaymentScheduleItem) => (
          <>
            {Number(record?.payment_schedule_item_type_id) == 8 ? (
              ""
            ) : (
              <Dropdown
                menu={{
                  items: actionItems(record),
                }}
              >
                <button>
                  <EllipsisHorizontalIcon className="w-6 h-6 text-primary500" />
                </button>
              </Dropdown>
            )}
          </>
        ),
      },
    ] || []
  const actionItems = (record: IPaymentScheduleItem) => {
    const id = Number(record?.payment_schedule_item_status_id)
    const paymentid = record?.payment_schedule_item_id
    const selected = record?.is_selected
    const active = record?.is_active
    const payment_method_id = record?.payment_method_id
    const payment_schedule_item_type_id = record?.payment_schedule_item_type_id

    let arrayOptions: any = []

    {
      payment_method_id == "1" &&
        (id === 1 || id === 4) &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: (
              <div
                // onClick={() => {
                //   setActive(record)
                //   setShowViewPayout(true)
                // }}
                onClick={() => {
                  setActiveReference(record?.reference_number)
                  setShowDetails(true)
                }}
              >
                {" "}
                View
              </div>
            ),
          },
        ])
    }
    {
      id == 1 &&
        active &&
        payment_schedule_item_type_id === "1" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenReverseConfirmation(true)
                }}
              >
                {" "}
                Reverse
              </div>
            ),
          },
        ])
    }
    {
      id == 3 &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "3",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenUnreverseConfirmation(true)
                }}
              >
                {" "}
                Unreverse
              </div>
            ),
          },
        ])
    }
    {
      id == 1 &&
        payment_method_id !== "1" &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "4",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenResetConfirmation(true)
                }}
              >
                {" "}
                Reset
              </div>
            ),
          },
        ])
    }
    {
      id == 4 &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "5",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenRetryConfirmation(true)
                }}
              >
                {" "}
                Retry
              </div>
            ),
          },
        ])
    }
    {
      ;(id == 4 || id == 5 || payment_method_id !== "1") &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "6",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenUpdateConfirmation(true)
                }}
              >
                {" "}
                Update
              </div>
            ),
          },
        ])
    }
    {
      id == 2 &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "7",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenRefreshConfirmation(true)
                }}
              >
                {" "}
                Refresh
              </div>
            ),
          },
        ])
    }
    {
      ;(id == 4 || id == 5) &&
        active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "8",
            label: (
              <div
                onClick={() => {
                  if (canDelete) {
                    setPaymentId(String(paymentid))
                    setOpenDeleteConfirmation(true)
                  } else {
                    toast.unauthorized()
                  }
                }}
              >
                Remove
              </div>
            ),
          },
        ])
    }
    {
      ;(id == 4 || id == 5) &&
        !active &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "9",
            label: (
              <div
                onClick={() => {
                  setPaymentId(String(paymentid))
                  setOpenRestoreConfirmation(true)
                }}
              >
                {" "}
                Restore
              </div>
            ),
          },
        ])
    }

    return arrayOptions
  }
  const allActionItems = [
    {
      key: "1",
      label: (
        <button
          className="w-full flex justify-start"
          onClick={() => {
            setOpenSelectAllConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          Select
        </button>
      ),
    },
    {
      key: "6",
      label: (
        <button
          className="w-full flex justify-start"
          onClick={() => {
            setOpenDeselectAllConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          Deselect
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenReverseConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Reverse
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenUnreverseConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Unreverse
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenResetConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Reset
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenRefreshConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Refresh
        </div>
      ),
    },
    {
      key: "7",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenRetryConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Retry
        </div>
      ),
    },
    {
      key: "8",
      label: (
        <div
          className="w-full flex justify-start"
          onClick={() => {
            setOpenUpdateConfirmation(true)
            setPaymentId(getIdFromKey(x || ""))
          }}
        >
          {" "}
          Update
        </div>
      ),
    },
  ]
  const headers = [
    { label: "Bank Name", key: "bank_name" },
    { label: "Account Number", key: "account_number" },
    { label: "Full Name", key: "full_name" },
    { label: "Beneficiary Name", key: "beneficiary_name" },

    { label: "Currency", key: "currency_code" },
    { label: "Source Currency", key: "source_currency_code" },
    { label: "Amount", key: "amount" },
    { label: "Routing Code", key: "routing_code" },
    { label: "Routing Type", key: "routing_type" },
    { label: "Email", key: "email" },
    { label: "Phone", key: "bankAccountNumber" },
    { label: "Status", key: "payment_schedule_item_status" },
    { label: "Reference", key: "reference_number" },
  ]

  const csvProps = {
    filename: `${data?.description}.csv`,
    headers: headers,
    data: filteredData || [],
  }
  return (
    <div className="relative">
      <Spin
        spinning={isPaymentLoading}
        indicator={antIcon}
      >
        <div className=" flex overflow-x-auto scrollbar-hide  mb-4 gap-[11px] justify-start">
          {cards.map((card, index) => (
            <div
              key={index}
              className={index > 2 ? "hidden lg:flex" : "flex w-full"}
            >
              <PayoutCard
                id={card.id}
                key={index}
                title={card.title}
                value={card.value}
                icon={card.icon}
                type={type == card.typeId ? true : false}
                handleClick={card.handleClick}
                currency={activeCurrency?.currency_symbol}
              />
            </div>
          ))}
        </div>
      </Spin>
      <div className=" bg-white rounded-5px mt-4 ">
        <div className=" flex items-center justify-between py-[10px] px-4">
          <div className="flex  gap-2 items-center  ">
            <div className="w-[268px] h-9">
              <Input
                placeholder="Search"
                prefix={
                  <MagnifyingGlassIcon className="w-4 h-4 text-neutral-300" />
                }
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="font-avenir "
              />
            </div>
          </div>

          <div className="flex  items-center gap-3">
            <ResponsiveActinBtn
              items={[
                {
                  key: "process",
                  label: (
                    <button
                      className="w-full flex justify-start"
                      onClick={() => {
                        setProcessSelected(true)
                      }}
                      disabled={
                        data?.has_selected_unprocessed_payments ? false : true
                      }
                    >
                      Process selected payments{" "}
                    </button>
                  ),
                },

                ...allActionItems,
              ]}
            >
              <Dropdown
                menu={{
                  items: allActionItems,
                }}
              >
                <button className=" h-8 border border-neutral100 bg-white flex justify-between px-4 items-center rounded-5px w-[190px] text-padeBlack text-sm">
                  Batch payout actions
                  <ChevronDownIcon className=" text-black  w-5 h-5" />
                </button>
              </Dropdown>
            </ResponsiveActinBtn>
            {user?.organisationPlan === "Custom" &&
            user?.customPlanPayrollBilling === "True" ? null : (
              <CSVLink {...csvProps}>
                <Button
                  title="download"
                  color="primary"
                  // onClick={() => {
                  //   setOpenDownloadConfirmation(true)
                  // }}
                >
                  Download payment file
                </Button>
              </CSVLink>
            )}
          </div>
        </div>
        <div className="border relative mb-10">
          <SelectedTable
            TableColumns={TableColumns}
            TableData={TableData}
            isPaymentLoading={isPayoutLoading}
            submitSelection={submitSelection}
            submitDeselection={submitDeselection}
            setAllSelectedRowSum={setAllSelectedRowSum}
            setFilteredData={setFilteredData}
          />
        </div>
      </div>

      {processSelected && (
        <ProcessPayment
          open={processSelected}
          onClose={() => {
            setProcessSelected(false)
          }}
          activeCurrency={activeCurrency}
          invalidateQueries={invalidateQueries}
        />
      )}
      {activeReference && (
        <ViewTransferDetails
          reference={activeReference}
          onClose={() => {
            setActiveReference("")
            setShowDetails(false)
          }}
          open={showDetails}
          showFee={false}
        />
      )}
      {openDownloadConfirmation && (
        <DownloadPayment
          open={openDownloadConfirmation}
          onClose={() => {
            setOpenDownloadConfirmation(false)
          }}
          data={data}
        />
      )}
      {/* VIEW */}
      <ViewPayoutModal
        open={showViewPayout}
        onClose={() => {
          setActive(null)
          setShowViewPayout(false)
        }}
        payment={active}
      />
      <ReversePayment
        open={openReverseConfirmation}
        onClose={() => {
          setOpenReverseConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        invalidateQueries={invalidateQueries}
        x={x || ""}
        currency={activeCurrency?.currency_code}
      />
      <UnreversePayment
        open={openUnreverseConfirmation}
        onClose={() => {
          setOpenUnreverseConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        invalidateQueries={invalidateQueries}
        x={x || ""}
        currency={activeCurrency?.currency_code}
      />
      <ResetPayment
        open={openResetConfirmation}
        onClose={() => {
          setOpenResetConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        invalidateQueries={invalidateQueries}
        x={x || ""}
        currency={activeCurrency?.currency_code}
      />

      <UpdatePayment
        open={openUpdateConfirmation}
        onClose={() => {
          setOpenUpdateConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        x={x || ""}
        invalidateQueries={invalidateQueries}
        currency={activeCurrency?.currency_code}
      />

      <RetryPayment
        open={openRetryConfirmation}
        onClose={() => {
          setOpenRetryConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        x={x || ""}
        invalidateQueries={invalidateQueries}
        currency={activeCurrency?.currency_code}
      />
      <RefreshPayment
        open={openRefreshConfirmation}
        onClose={() => {
          setOpenRefreshConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        x={x || ""}
        invalidateQueries={invalidateQueries}
        currency={activeCurrency?.currency_code}
      />

      <DeletePayment
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        invalidateQueries={invalidateQueries}
        // currency={activeCurrency?.currency_code}
      />

      <RestorePayment
        open={openRestoreConfirmation}
        onClose={() => {
          setOpenRestoreConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        invalidateQueries={invalidateQueries}
        // currency={activeCurrency?.currency_code}
      />
      <SelectPayment
        open={openSelectAllConfirmation}
        onClose={() => {
          setOpenSelectAllConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        x={x || ""}
        invalidateQueries={invalidateQueries}
        currency={activeCurrency?.currency_code}
      />
      <DeselectPayment
        open={openDeselectAllConfirmation}
        onClose={() => {
          setOpenDeselectAllConfirmation(false)
          setPaymentId(getIdFromKey(x || ""))
        }}
        paymentId={paymentId}
        x={x || ""}
        invalidateQueries={invalidateQueries}
        currency={activeCurrency?.currency_code}
      />

      {/* FOOTER */}
      <div className="fixed bottom-0 flex justify-end left-0 right-0 h-[64px] gap-4  py-3 px-16 bg-white ">
        {activeCurrency?.currency_code === "NGN" &&
          data?.payment_schedule_type_id === 1 && (
            <Button
              color="secondary"
              submitting={isPaymentLoading}
              onClick={() =>
                navigate(`/dashboard/payment-schedules/statustory?x=${x}`, {
                  state: {
                    title: data?.description,
                    currency_code: activeCurrency?.currency_code,
                  },
                })
              }
            >
              Statutory Payments
            </Button>
          )}
        <Button
          color="primary"
          onClick={() => {
            setProcessSelected(true)
          }}
          submitting={isPaymentLoading}
          disabled={data?.has_selected_unprocessed_payments ? false : true}
        >
          Process Selected Payments
        </Button>
      </div>
    </div>
  )
}

export default ViewCurrency
type Key = string | number | symbol | bigint
