import { Suspense, useState } from "react"
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, DashboardContainer, P1, P2 } from "components"
import getIdFromKey from "utils/getIdFromKey"

import ImageComponent from "components/custom/image"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { IPersonalDetails } from "./personal/interface/Personal"
import { axiosInstance } from "utils/request"
import { Spin, Upload } from "antd"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import { useAppNotificationStore } from "stores"
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface"
import type { UploadChangeParam } from "antd/es/upload"
import { InboxOutlined } from "@ant-design/icons"
import usePermissions from "hooks/usePermissions"
import { useCustomizationSettingsStore } from "stores/customization-settings"

type ActionType =
  | "delete"
  | "remove_profile"
  // | "suspend"
  // | "reinstate"
  | "add_profile"
  | undefined

const AllRequests: React.FC<{}> = () => {
  const [showEditImageModal, setShowEditImageModal] = useState(false)
  const { pathname } = useLocation()
  const { employeeKey } = useParams()
  const employeeId = getIdFromKey(employeeKey || "")
  const navigate = useNavigate()

  const [deleteSuspnedRemoveModal, setDeleteSuspnedRemoveModal] = useState({
    type: undefined as ActionType,
    employeeId: "",
    firstName: "",
  })

  const [canDeleteEmployees] = usePermissions({
    allowedPermissions: ["can_delete_employees"],
  })

  const { isLoading: isDetailsLoading, data: employee } =
    useQuery<IPersonalDetails>({
      queryKey: ["employee-profile", employeeKey],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `admin/employees/view/index_personal_info?id=${employeeKey}`
        )
        return res.data
      },
    })

  const tabs = [
    {
      index: 0,
      title: "Job & Pay",
      to: `/dashboard/employee/${employeeKey}/job-and-pay`,
    },
    {
      index: 1,
      title: "Personal",
      to: `/dashboard/employee/${employeeKey}/personal`,
    },
    {
      index: 2,
      title: "Request",
      to: `/dashboard/employee/${employeeKey}/requests`,
    },
    {
      index: 3,
      title: "Documents",
      to: `/dashboard/employee/${employeeKey}/documents`,
    },
    {
      index: 4,
      title: "Benefits",
      to: `/dashboard/employee/${employeeKey}/benefits`,
    },
    {
      index: 5,
      title: "Assets",
      to: `/dashboard/employee/${employeeKey}/assets`,
    },
    {
      index: 6,
      title: "Tasks",
      to: `/dashboard/employee/${employeeKey}/tasks`,
    },
    {
      index: 7,
      title: "Disciplinary",
      to: `/dashboard/employee/${employeeKey}/disciplinary`,
    },
    {
      index: 8,
      title: "Notes",
      to: `/dashboard/employee/${employeeKey}/notes`,
    },
    {
      index: 9,
      title: "Logs",
      to: `/dashboard/employee/${employeeKey}/logs`,
    },
  ]

  return (
    <>
      <div className="h-full flex items-center gap-6 px-4 md:px-6 lg:px-10 border-y border-y-neutral40 bg-white overflow-x-auto">
        {tabs.map((item, index) => {
          const isActive = pathname.includes(item.to)
          return (
            <div
              className={` h-full relative flex items-center cursor-pointer py-4`}
              onClick={() => {
                navigate(item.to)
              }}
              key={index}
            >
              <P1
                className={`whitespace-nowrap leading-none ${
                  isActive ? "font-semibold text-primary500" : "text-neutral100"
                }`}
              >
                {item.title}
              </P1>
              {isActive && (
                <div className="w-full max absolute  bottom-0 h-[3px] bg-primary500" />
              )}
            </div>
          )
        })}
      </div>
      <div className="w-full mx-auto flex flex-col gap-4">
        <div className="w-full bg-[#ededed]min-h-screen flex flex-col gap-10 relative">
          <DashboardContainer>
            {isDetailsLoading ? (
              <Spin>
                <div className="w-full h-screen bg-white grid place-content-center"></div>
              </Spin>
            ) : (
              <>
                <div className="rounded-xl shadow bg-white overflow-hidden mb-6">
                  <div className="w-full h-[100px] md:h-[158px]">
                    <img
                      className="w-full h-full object-cover"
                      src={
                        "https://padefiles.blob.core.windows.net/images/86794827-c3cc-4674-81de-de3e807e6b86.png"
                      }
                      alt="logo"
                    />
                  </div>
                  <div className="py-7 px-5 flex items-start justify-between">
                    <div className=" relative w-full">
                      <div className="rounded-full overflow-hidden p-1 md:p-2 bg-white absolute -top-16 md:-top-[120px] ">
                        <div
                          className="relative group cursor-pointer h-20 w-20 md:h-32 md:w-32 overflow-hidden"
                          role="button"
                          onClick={() => setShowEditImageModal(true)}
                        >
                          <ImageComponent
                            src={
                              employee?.avatar_url ||
                              "https://padefiles.blob.core.windows.net/images/cb72f9ce-d835-4666-965a-cc06836cd48f.svg"
                            }
                            alt="image"
                            className="object-cover w-full h-full rounded-full"
                          />
                          <div className="hidden group-hover:grid place-content-center absolute inset-0 bg-black/60 text-white font-semibold font-circular pointer-events-none rounded-full">
                            edit
                          </div>
                        </div>
                      </div>

                      <div className=" w-full space-y-4 sm:space-y-0 sm:flex justify-between sm:flex-wrap gap-4 mt-6 md:items-end">
                        <div>
                          <h2 className="text-[#243757] font-circular text-[28px] font-bold  leading-none tracking-tight flex gap-2 md:gap-4 flex-wrap items-center">
                            {employee?.full_name}{" "}
                            <span
                              className={`font-avenir text-sm font-medium py-0.5 px-3 rounded ${
                                employee?.person_status === "Active"
                                  ? "bg-success100 text-success800"
                                  : "bg-danger100 text-danger500"
                              }`}
                            >
                              {employee?.person_status}
                            </span>
                          </h2>
                          <div className="flex items-center justify-start mt-4">
                            <p className="text-[#243757] text-sm mr-5">
                              {employee?.employee_title}
                            </p>
                            <p className="text-[#7A8699] font-circular text-sm">
                              {employee?.employment_status}
                            </p>
                          </div>
                          <div className="flex items-center justify-start mt-2">
                            <p className="text-[#243757] bg-[#E8F0FE] px-3 py-1 rounded font-circular text-xs mr-5">
                              {employee?.office_display_name}
                            </p>
                            <p className="text-[#5D6B82] text-sm font-bold">
                              <a href={`tel:${employee?.mobile_phone}`}>
                                {employee?.mobile_phone}
                              </a>
                            </p>
                          </div>
                        </div>

                        {canDeleteEmployees && (
                          <div className="grid gap-4 sm:grid-cols-3 grow">
                            {/* <Button
                              color={
                                employee?.person_status_id === 3
                                  ? "primary"
                                  : "destructive"
                              }
                              onClick={() => {
                                setDeleteSuspnedRemoveModal({
                                  type:
                                    employee?.person_status_id === 3
                                      ? "reinstate"
                                      : "suspend",
                                  employeeId: employeeKey || "",
                                  firstName: employee?.first_name || "",
                                })
                              }}
                            >
                              {employee?.person_status_id === 3
                                ? "Reinstate"
                                : "Suspend"}
                            </Button> */}
                            <div />
                            <Button
                              color="destructive"
                              onClick={() => {
                                setDeleteSuspnedRemoveModal({
                                  type: "delete",
                                  employeeId: employeeKey || "",
                                  firstName: employee?.first_name || "",
                                })
                              }}
                            >
                              Delete
                            </Button>
                            <Button
                              color={
                                employee?.has_profile
                                  ? "destructive"
                                  : "primary"
                              }
                              onClick={() => {
                                setDeleteSuspnedRemoveModal({
                                  type: employee?.has_profile
                                    ? "remove_profile"
                                    : "add_profile",
                                  employeeId: employeeKey || "",
                                  firstName: employee?.first_name || "",
                                })
                              }}
                            >
                              {employee?.has_profile ? "Remove" : "Create"}{" "}
                              Profile
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Suspense
                  fallback={
                    <div
                      className="fixed right-0 top-32 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-transparent"
                      style={{
                        zIndex: 9999999,
                      }}
                    >
                      <Spin />
                    </div>
                  }
                >
                  <Outlet />
                </Suspense>
              </>
            )}
          </DashboardContainer>
        </div>
      </div>

      <EditUserProfileImage
        setShowEditModal={setShowEditImageModal}
        showEditModal={showEditImageModal}
        avatarUrl={employee?.avatar_url}
      />

      <DeleteSuspnedRemoveModal
        type={deleteSuspnedRemoveModal.type}
        employeeId={deleteSuspnedRemoveModal.employeeId}
        open={deleteSuspnedRemoveModal.type !== undefined}
        onClose={() =>
          setDeleteSuspnedRemoveModal({
            type: undefined,
            employeeId: "",
            firstName: "",
          })
        }
        employeeFName={deleteSuspnedRemoveModal?.firstName}
      />
    </>
  )
}

export default AllRequests

const DeleteSuspnedRemoveModal = ({
  type,
  employeeId,
  employeeFName,
  open,
  onClose,
}: {
  type: ActionType
  employeeId: string
  employeeFName: string
  onClose: () => void
  open: boolean
}) => {
  const { toast } = useAppNotificationStore()
  const [showForceDeleteModal, setShowForceDeleteModal] = useState(false)
  const [warningMsg, setWarningMsg] = useState("")
  const [reason, setReason] = useState("")
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { employeeKey } = useParams()
  const { customizationSettings } = useCustomizationSettingsStore()

  const { isLoading, mutate, data } = useMutation({
    mutationFn: async (forceDelete: boolean) => {
      if (forceDelete) {
        const res = await axiosInstance.post(
          `/admin/employees/delete?id=${employeeId}&force=true`
        )
        return res.data
      }

      // if (type === "reinstate" || type === "suspend") {
      //   const body = {
      //     person_list: [getIdFromKey(employeeId || "")],
      //     reason,
      //   }
      //   const res = await axiosInstance.post(`/admin/employees/${type}`, body)
      //   return res.data
      // }

      const res = await axiosInstance.post(
        `/admin/employees/${type}?id=${employeeId}`
      )
      return res.data
    },
    onSuccess: (data, forceDelete) => {
      if (data.status === "success") {
        onClose()
        setShowForceDeleteModal(false)
        data?.msg && toast.success(data.msg)
        if (forceDelete) {
          navigate("/dashboard/employees")
        }
      } else if (data.status === "warning") {
        if (type === "delete") {
          setShowForceDeleteModal(true)
          setWarningMsg(data.msg)
        }
      } else if (data.status === "error") {
        data?.msg && toast.error(data.msg)
        onClose()
      }

      queryClient.invalidateQueries({
        queryKey: ["employee", employeeKey],
      })
    },
  })

  return (
    <>
      <CustomModal
        destroyOnClose
        open={open}
        title={
          type === "delete"
            ? `Delete Employee`
            : type === "remove_profile"
            ? `Remove Employee Profile`
            : `Create Employee Profile`
        }
        onCancel={onClose}
        width={500}
        footer={
          <div className="w-full justify-end flex gap-4">
            <Button
              title={`Cancel`}
              color="neutral"
              className="font-circular"
              key="cancel-delete"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color={type === "add_profile" ? "primary" : "destructive"}
              type="submit"
              disabled={isLoading}
              submitting={isLoading}
              onClick={() => {
                mutate(false)
              }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 ">
          <P2 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
            {type === "delete"
              ? `Deleting an employee can’t be undone and we won’t keep this employee information once it has been deleted. Click Confirm to continue.`
              : type === "remove_profile"
              ? `If you remove ${employeeFName} profile, they won’t be able to login and view their payslips, make requests and participate in company activities but you’ll still be able to manage their information as normal. You can create their profile again later. Click Confirm to continue.`
              : type === "add_profile"
              ? `${employeeFName} will be able to login and view their payslips, make requests and participate in company activities after you create their user profile. You can remove their employee profile later. Click Confirm to continue.`
              : type === "suspend"
              ? `If you suspend ${employeeFName}, they won’t be able to log in to view their payslips, submit requests, or participate in company activities. However, you will still retain the ability to manage their information as usual. They will also be excluded from upcoming payrolls. You can reinstate them later. Please provide a reason to continue.`
              : `If you reinstate ${employeeFName}, they will be able to login and view their payslips, make requests and participate in company activities. They will also be included in upcoming payrolls. Click Confirm to continue.`}
          </P2>

          {/* {type === "suspend" && (
            <Input.TextArea
              className="h-10"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          )} */}
        </div>
      </CustomModal>

      <CustomModal
        destroyOnClose
        open={showForceDeleteModal}
        title={`Force Delete Employee`}
        onCancel={() => {
          setShowForceDeleteModal(false)
          onClose()
        }}
        width={500}
        footer={
          <div className="w-full justify-end flex gap-4">
            <Button
              color="neutral"
              onClick={() => {
                setShowForceDeleteModal(false)
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              color={"danger"}
              type="submit"
              disabled={isLoading}
              submitting={isLoading}
              onClick={() => {
                mutate(true)
              }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 ">
          <P2 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
            {warningMsg}
          </P2>
        </div>
      </CustomModal>
    </>
  )
}

const EditUserProfileImage = ({
  showEditModal,
  setShowEditModal,
  avatarUrl,
}: {
  showEditModal: boolean
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
  avatarUrl: string | undefined
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { toast } = useAppNotificationStore()
  const [file, setFile] = useState<any>(null)
  const [imgUrl, setImgUrl] = useState(avatarUrl || "")
  const { employeeKey } = useParams()
  const employeeId = getIdFromKey(employeeKey || "")

  const { isLoading: isAddingFile, mutate: submitFile } = useMutation({
    mutationFn: async (formData: FormData) => {
      const res = await axiosInstance.post(
        `/admin/employees/upload_image?id=${employeeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status === "success") {
        toast.success("File uploaded successfully!")
        setShowEditModal(false)
        setFile(null)
        setImgUrl("")
        window.location.reload()
      }
    },
  })

  const handleUpload = (id: string) => {
    if (file) {
      const formData = new FormData()
      formData.append("image_upload", file)
      formData.append("id", id)
      submitFile(formData)
    }
  }

  const beforeUpload = (file: RcFile) => {
    // Only png, jpg, jpeg file are allowed
    const isCorrectFormat =
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    // 500kb limit

    const isLimit = file.size / 1024 / 1024 < 0.5 // 500kb

    if (!isCorrectFormat) {
      toast.error("You can only upload pdf, png, jpg, jpeg file!")
    }
    if (!isLimit) {
      toast.error("File must be smaller than 500kb!")
    }
    return isCorrectFormat && isLimit
  }

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "uploading") {
      setIsLoading(true)
      return
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setIsLoading(false)
        setFile(info.file.originFileObj)
        setImgUrl(url)
      })
    }
  }

  const customRequest = async (options: any) => {
    setTimeout(() => {
      options.onSuccess("ok")
    }, 0)
  }

  return (
    <CustomModal
      destroyOnClose
      open={showEditModal}
      title={"Upload Profile Picture"}
      onCancel={() => {
        setShowEditModal(false)
      }}
      width={500}
      footer={
        <div className="w-full justify-end flex gap-4">
          <Button
            title={`Cancel`}
            color="neutral"
            className="font-circular"
            key="cancel-delete"
            onClick={() => {
              setShowEditModal(false)
              setFile(null)
              setImgUrl("")
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            disabled={isAddingFile}
            submitting={isAddingFile}
            onClick={() => {
              handleUpload(employeeId)
            }}
          >
            Save
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-4 ">
        <P2 className="text-sm pb-5 text-dark30 text-[12px] leading-[18px]">
          We recommend a size of 200px * 200px for profile picture. Note:
          Maximum allowed file size is 500KB.
        </P2>
        <Spin spinning={isLoading}>
          <Upload.Dragger
            name="upload-companyLogo"
            className=" font-avenir"
            beforeUpload={beforeUpload}
            onChange={handleChange}
            customRequest={customRequest}
            multiple={false}
            maxCount={1}
            onDrop={(e) => {
              e.preventDefault()
            }}
            showUploadList={false}
          >
            {imgUrl ? (
              <img
                src={imgUrl}
                alt="avatar"
                className={`w-full h-full object-cover max-h-[200px]`}
              />
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>

                <Button
                  color="secondary"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  Choose file
                </Button>
              </>
            )}
          </Upload.Dragger>
        </Spin>
      </div>
    </CustomModal>
  )
}

export const getBase64 = async (
  img: RcFile,
  callback: (url: string) => void
) => {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result as string))
  reader.readAsDataURL(img)
}

// type === "delete"
//   ? "delete"
//   : type === "remove"
//   ? "remove_profile"
//   : type === "suspend"
//   ? "suspend"
//   : "add_profile"
