import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline"
import { useQueryClient } from "@tanstack/react-query"
import { Dropdown, Select, Table } from "antd"
import { DashboardContainer, H6 } from "components"
import { useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"
import { useGet } from "utils/useFetch"
import { surveyTypes } from "../Survey"
import DeleteSurvey from "../modals/DeleteSurvey"

const Surveys = () => {
  const queryClient = useQueryClient()
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")
  const [typeId, setTypeId] = useState("1")
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState("")

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`admin/surveys/select?typeId=${typeId}`],
    })
  }

  const { isLoading: isLoadingSurvey, data: surveyData } = useGet<any>({
    url: `admin/surveys/select?typeId=${typeId}`,
  })

  const TableData = useMemo(
    () =>
      surveyData?.data?.map((survey: ISurvey) => ({
        key: survey.survey_id,
        description: survey.description,
        status: {
          id: survey.survey_status_id,
          text: survey.survey_status,
        },
        action: {
          id: survey.survey_id,
          key: survey.survey_key,
          statusId: survey?.survey_status_id,
          typeId: survey.survey_type_id,
        },
      })) || [],
    [surveyData]
  )

  const TableColumns = useMemo(
    () =>
      [
        {
          title: "Description",
          dataIndex: "description",
          render: (description: string) => (
            <span
              className={`whitespace-nowrap  font-avenir text-sm text-[#42526D]
               `}
            >
              {description}
            </span>
          ),
        },

        {
          title: "Status",
          dataIndex: "status",
          render: (status: { text: string; id: string }) => (
            <span
              className={`text-sm font-medium py-1 block text-center w-[80px] rounded-[4px]
                    ${status.id === "2" ? "bg-success100 text-success800" : ""}
                    ${status.id === "1" ? "bg-neutral40 text-neutral500" : ""}
                    ${status.id === "3" ? "bg-[#FEE3B1] text-[#E69702]" : ""}

                    `}
            >
              {status.text}
            </span>
          ),
        },

        {
          title: "",
          dataIndex: "action",
          key: "action",
          render: (data: {
            key: string
            id: number
            statusId: string
            typeId: string
          }) => (
            <Dropdown
              menu={{
                items: actionItems(data),
              }}
            >
              <button>
                <EllipsisHorizontalIcon className="w-6 h-6 text-[#004AF5]" />
              </button>
            </Dropdown>
          ),
        },
      ] || [],
    []
  )
  //  three dots on each row
  const actionItems = (data: {
    key: string
    id: number
    statusId: string
    typeId: string
  }) => {
    let arrayOptions: any = []
    {
      data?.statusId === "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "0",
            label: (
              <Link to={`/dashboard/survey/preview?x=${data?.key}`}>
                Preview
              </Link>
            ),
          },
        ])
    }
    {
      data?.statusId === "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "1",
            label: (
              <Link to={`/dashboard/survey/edit?x=${data?.key}`}>Edit</Link>
            ),
          },
        ])
    }

    {
      data?.statusId !== "2" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  navigate(`/dashboard/survey/edit?x=${data?.key}`)
                }}
              >
                Finish
              </div>
            ),
          },
        ])
    }
    {
      data?.statusId === "2" &&
        data?.typeId === "1" &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "3",
            label: (
              <div
                onClick={() => {
                  navigate(`/dashboard/survey/assign?x=${data?.key}`)
                }}
              >
                Assign
              </div>
            ),
          },
        ])
    }
    {
      data?.statusId &&
        (arrayOptions = [
          ...arrayOptions,
          {
            key: "4",
            label: (
              <div
                onClick={() => {
                  handleOpenDeleteModal(String(data.id))
                }}
              >
                Delete
              </div>
            ),
          },
        ])
    }

    return arrayOptions
  }
  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteConfirmation(true)
    setActiveDeleteId(String(id))
  }

  return (
    <div className="relative !font-avenir text-[#42526D]">
      <DashboardContainer>
        <div className="flex flex-col h-full">
          <div className="flex justify-between items-center mb-4">
            <H6 className="">Surveys</H6>
            <Select
              defaultValue={typeId}
              style={{ width: 125 }}
              placeholder="Filter by"
              onChange={(value: string) => {
                setTypeId(value)
              }}
              options={surveyTypes.map((surv) => ({
                value: surv.survey_id,
                label: surv.survey_title,
              }))}
              className="font-avenir "
            />
          </div>
          <Table
            columns={TableColumns}
            dataSource={TableData}
            loading={isLoadingSurvey}
            className="text-[#42526D] min-h-screen"
            pagination={{
              total: surveyData?.total,
              defaultPageSize: 20,
            }}
          />
        </div>
      </DashboardContainer>
      <DeleteSurvey
        title="Delete Survey"
        description="  If you delete this survey, it would be removed from your list of
          surveys. Are you sure you want to continue?"
        url="/admin/surveys/remove"
        activeDeleteId={activeDeleteId}
        open={openDeleteConfirmation}
        onClose={() => {
          setOpenDeleteConfirmation(false)
          setActiveDeleteId("")
        }}
        invalidateQueries={invalidateQueries}
      />
    </div>
  )
}
export default Surveys

export interface ISurvey {
  survey_id: string
  survey_key: string
  survey_status_id: string
  survey_status: string
  survey_type_id: string
  survey_type: null
  description: string
  is_active: string
}
