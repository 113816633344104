import React from "react"
import { OverviewFeedback } from "../interface"
import Positive from "assets/svg/positive.svg"
import Negative from "assets/svg/negative.svg"
import { UserIcon } from "@heroicons/react/24/solid"
interface props {
  item: OverviewFeedback | any
}
const Feedback = ({ item }: props) => {
  return (
    <div className="border rounded py-2.5 px-3 flex items-start gap-2.5">
      <img
        src={item?.feedback_type === "Positive" ? Positive : Negative}
        alt=""
        className="h-6 w-6"
      />
      <div className="">
        <h6 className="text-sm  font-circular text-padeBlack">
          {item?.description}
        </h6>
        <div className="flex items-center  mt-1">
          <span className="text-[#6c7a9399] text-[13px] font-circular mr-6">
            {item?.created_date}
          </span>
          {item?.nick_name && (
            <span className="text-[#6c7a9399] text-[13px] font-circular flex items-center gap-0.5">
              <UserIcon className="h-3 w-3" />

              {item?.nick_name}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Feedback
