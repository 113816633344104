import React, { Suspense } from "react"
import { Alert, App, Layout, Spin } from "antd"
import { useState, useEffect } from "react"
import { useAuthStore } from "stores"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import { SideNavigationBar } from "./SideNavigationBar"
import { TopNavigationBar } from "./TopNavigationBar"

import { NotificationBar } from "./NotificationBar"
import UnauthourizedPage from "components/layouts/UnauthourizedPage"
import IdleTimer from "IdleTimer"
import { Tour } from "antd"
import { isPadeDomain } from "utils/utils"
import { getTourStepsForRoute } from "utils/tourGuide"
import Intercom from "@intercom/messenger-js-sdk"
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline"
import AccountSuspensionPage from "components/layouts/AccountSuspensionPage"
import { mixPanelEvent } from "../mix-panel/MixPanel"
import { useAuthModalStore } from "stores/auth-modal"

const { Header, Content } = Layout

const NewResponsiveAdminLayout: React.FC = () => {
  const navigate = useNavigate()
  const [isSidebarOpen, toggleSidebar] = useState(false)
  const [tourSteps, setTourSteps] = useState<any>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const location = useLocation()
  const [hasPendingInvoice, setHasPendingInvoice] = useState(false)

  const { user, isAuthenticated, signOut } = useAuthStore((state) => state)
  const isAuthModalOpen = useAuthModalStore((state) => state.isOpen)

  useEffect(() => {
    if (location.pathname) {
      setTourSteps([])
      setOpen(false)
      const steps = getTourStepsForRoute(location.pathname)
      setTourSteps(steps)
      setCurrentIndex(0)
    }
  }, [location.pathname])

  useEffect(() => {
    if (user) {
      setHasPendingInvoice(
        user?.hasPendingInvoice === "True" &&
          user?.isBillingNotificationEnabled === "True"
          ? true
          : false
      )
    }
  }, [user])
  useEffect(() => {
    const steps = getTourStepsForRoute(location.pathname)
    setTourSteps(steps)
    setCurrentIndex(0)
  }, [])

  if (!isAuthenticated && !isAuthModalOpen) {
    const presentLocation = window ? window.location?.href : ""
    return (
      <Navigate
        to={`/auth/login?redirect=${encodeURIComponent(presentLocation)}`}
      />
    )
  }

  if (user?.isAdmin === "False") {
    return <UnauthourizedPage type="employee" />
  }
  if (
    user?.hasDueInvoice &&
    user?.isBillingNotificationEnabled === "True" &&
    !location.pathname.startsWith("/dashboard/settings/plan-and-billin")
  ) {
    return <AccountSuspensionPage />
  }

  const startTourHandler = () => {
    showTourStep()
    setOpen(true)
    setCurrentIndex(0)
    // console.log("Tour started")
  }

  const showTourStep = () => {
    if (tourSteps && tourSteps[currentIndex]) {
      console.log(tourSteps)
      const step = tourSteps[currentIndex]
    }
  }
  const endTourHandler = () => {
    // setTourSteps([])
    setCurrentIndex(0)
    setOpen(false)
  }

  isPadeDomain() === true &&
    Intercom({
      app_id: "bi604cch",
      user_id: user?.userId,
      name: user?.userFullName,
      email: user?.userName,
    })

  return (
    <>
      {open && (
        <Tour
          open={open}
          onClose={endTourHandler}
          steps={tourSteps}
          closeIcon={true}
        />
      )}

      {/* { ? (
        <div className="app">
          <Intercom
            appID="bi604cch"
            {...intercomUser}
          />
        </div>
      ) : null} */}

      <div className=""></div>
      <div className="relative">
        <App
          notification={{
            placement: "topRight",
          }}
        >
          <IdleTimer />

          <Layout>
            <SideNavigationBar
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={toggleSidebar}
            />

            <NotificationBar />

            <div className=" ">
              {hasPendingInvoice && (
                <div className="sticky top-0 z-20 md:pl-[280px] font-avenir  ">
                  <Alert
                    message="Your subscription invoice due soon"
                    type="warning"
                    showIcon
                    icon={<ExclamationTriangleIcon className=" h-12 w-12" />}
                    closable
                    description={`Your subscription invoice is pending. To avoid service interruption, click 'Pay Now' to complete your payment.`}
                    action={
                      <div className="pr-5">
                        <button
                          onClick={() => {
                            navigate(
                              "/dashboard/settings/plan-and-billing/invoices"
                            )
                          }}
                          className="bg-[#DD900D] px-4 py-1 text-white rounded"
                        >
                          Pay now{" "}
                        </button>
                      </div>
                    }
                    className="bg-[#FDF0DB] invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center  "
                  />
                </div>
              )}
              {user && user?.isEwaEnabled === "False" && (
                <div className="sticky top-0 z-20 md:pl-[280px] font-avenir  ">
                  <Alert
                    message="Give Your Team Financial Freedom "
                    type="warning"
                    showIcon
                    icon={
                      <InformationCircleIcon className="h-4 w-4 lg:h-12 lg:w-12 !text-primary500" />
                    }
                    closable
                    onClose={() => {
                      setHasPendingInvoice(false)
                    }}
                    description={
                      <div>
                        <p>
                          Turn On Earned Wage Access for your employees.{" "}
                          <a
                            target="_blank"
                            href="https://www.paidhr.com/blog/paidhr-launches-earned-wage-access"
                            className="underline text-primary500"
                          >
                            Learn more
                          </a>{" "}
                        </p>
                      </div>
                    }
                    action={
                      <div className="lg:pr-5  absolute lg:relative  bottom-2 lg:bottom-0  left-9 lg:left-0 ">
                        <button
                          onClick={() => {
                            mixPanelEvent(`ewa-alert-clicked-init`)
                            navigate("/dashboard/settings/hr-and-payroll/ewa")
                          }}
                          className="bg-primary500 px-4 py-1 text-white rounded"
                        >
                          Turn on{" "}
                        </button>
                      </div>
                    }
                    className="bg-[#CCDBFD] invoice-alert font-avenir text-neutral500 text-sm w-full flex justify-between items-center border-[#CCDBFD] !rounded-none "
                  />
                </div>
              )}

              <Layout>
                <Header
                  className={`bg-white p-0 sticky ${
                    hasPendingInvoice ? "top-[70px]" : "top-0"
                  } z-10 w-full border-b md:pl-[280px] h-[70px]`}
                >
                  <TopNavigationBar
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                    startTourHandler={startTourHandler}
                  />
                </Header>
                <Content className="">
                  <div className=" bg-[#F3F5FB] min-h-[100dvh] md:pl-[280px] font-avenir admin__layout">
                    <Suspense fallback={<Loading />}>
                      <Outlet />
                    </Suspense>
                  </div>
                </Content>
              </Layout>
            </div>
          </Layout>
        </App>
      </div>
    </>
  )
}

export default NewResponsiveAdminLayout

function Loading() {
  return (
    <div
      className="fixed right-0 top-20 bottom-0 left-0 md:left-[280px] flex justify-center items-center h-[calc(100vh-80px)] bg-white"
      style={{
        zIndex: 9999999,
      }}
    >
      <Spin />
    </div>
  )
}

// TourService.ts
export type TourStep = {
  title: string
  description: string
  target: () => HTMLElement | null
}

let tourSteps: TourStep[] = []
let currentIndex: number = 0

// Rest of the TourService logic...
