import { Button } from "components"
import React from "react"
import { Link, useNavigate } from "react-router-dom"

const NoWalletId = () => {
  const navigate = useNavigate()
  return (
    <div className="bg-white h-[546px] relative py-[59px] px-[52px] overflow-hidden">
      <div className="  w-[540px] ">
        <h2 className="mb-2.5 text-padeBlack font-bold font-circular text-[32px]">
          Your wallet is currently unavailable.
        </h2>
        <p className="mb-8 text-base font-circular text-[#0C1B3D80]">
          Request access to enjoy full financial services, track your balance
          and make seamless transactions.
        </p>
        <div className="mb-8">
          <div className="flex items-center gap-2.5 mb-3">
            <img
              src="https://padefiles.blob.core.windows.net/images/c6520b1a-600c-4511-927d-29cd555dc745.svg"
              alt=""
            />
            <p> Secure and Protected</p>
          </div>
          <div className="flex items-center gap-2.5 mb-3">
            <img
              src="https://padefiles.blob.core.windows.net/images/33fab75b-fbf0-47c2-9bdf-af1d2c31c2f7.svg"
              alt=""
            />
            <p>Verification Required</p>
          </div>
        </div>
        <Link to="https://support.paidhr.com">
          <Button color="primary">Contact support</Button>
        </Link>
      </div>
      <div className="absolute -top-20  -right-14">
        <img
          src="https://padefiles.blob.core.windows.net/images/0268eec2-ebcd-4753-88d0-f58550c36a2f.svg"
          alt=""
        />
      </div>
    </div>
  )
}

export default NoWalletId
