import { Table } from "antd"
import React, { useState, useEffect } from "react"
import { antIcon } from "../dashboard/Dashboard"
interface props {
  TableColumns: any
  TableData: any
  isPaymentLoading: any
  submitSelection: Function
  submitDeselection: Function
  setAllSelectedRowSum: Function
  setFilteredData: Function
}
export const SelectedTable: React.FC<props> = ({
  TableColumns,
  TableData,
  isPaymentLoading,
  submitSelection,
  submitDeselection,
  setAllSelectedRowSum,
  setFilteredData,
}) => {
  const [allSelectedRowKeys, setAllSelectedRowsKeys] = useState<any[]>([])

  const rowSelection = {
    onSelect: (selected: any) => {
      let found = allSelectedRowKeys.find((itm) => itm == selected.key)

      if (found) {
        submitDeselection([
          {
            payment_schedule_item_id: String(found),
          },
        ])
      } else {
        submitSelection([
          {
            payment_schedule_item_id: String(selected.key),
          },
        ])
      }
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      if (allSelectedRowKeys.length == TableData.length) {
        // ALL is selected,so deselect
        let selectedRowKeys = changeRows.map((itm: any) => itm.key)
        const updatedDeselected = selectedRowKeys.map(
          (sel: any, index: any) => {
            return {
              payment_schedule_item_id: String(sel),
            }
          }
        )
        let allSelectedIds = String(selectedRowKeys.join("/"))

        submitDeselection(updatedDeselected)
      } else {
        // All is not selected,so select
        let selectedRowKeys = selectedRows.map((itm: any) => itm.key)
        const updatedSelected = selectedRowKeys.map((sel: any, index: any) => {
          return {
            payment_schedule_item_id: String(sel),
          }
        })
        let allSelectedIds = String(selectedRowKeys.join("/"))
        submitSelection(updatedSelected)
      }
    },
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: any[],
      info: any
    ) => {
      const UpdatedSumRow = selectedRows.filter(
        (itm: any) => itm.payment_schedule_item_status !== "Succeeded"
      )

      const sumSelected = UpdatedSumRow.reduce(function (acc: any, obj: any) {
        return acc + obj.amount_unedited
      }, 0)
      setAllSelectedRowSum(sumSelected)
    },

    getCheckboxProps: (record: any) => {
      return {
        disabled:
          Number(record?.payment_schedule_item_status_id) < 3 ||
          record.is_active === false ||
          record.payment_schedule_item_type_id === "8"
            ? true
            : false,
        name: record.name,
      }
    },
  }
  useEffect(() => {
    let updatedSelectedKeys = TableData.filter((item: any) => {
      if (item.is_selected || item.payment_schedule_item_type_id === "8") {
        return item.key
      }
    })
    if (updatedSelectedKeys.length) {
      setAllSelectedRowsKeys(updatedSelectedKeys.map((ite: any) => ite.key))

      const UpdatedSumRow = updatedSelectedKeys.filter(
        (itm: any) => itm.payment_schedule_item_status !== "Succeeded"
      )

      const sumSelected = UpdatedSumRow.reduce(function (acc: any, obj: any) {
        return acc + obj.amount_unedited
      }, 0)
      setAllSelectedRowSum(sumSelected)
    } else {
      setAllSelectedRowsKeys([])
    }
  }, [TableData])

  return (
    <>
      <Table
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: allSelectedRowKeys,
          ...rowSelection,
        }}
        rowClassName={(record) => (!record.enabled ? "disabled-row" : "")}
        columns={TableColumns}
        dataSource={TableData}
        loading={{ spinning: isPaymentLoading, indicator: antIcon }}
        className="text-[#42526D]"
        pagination={{
          defaultPageSize: 20,
        }}
        onChange={(pagination, filters, sorter, extra) => {
          setFilteredData(extra.currentDataSource)
        }}
        scroll={{
          y: "calc(100vh - 300px)",
          x: "max-content",
        }}
      />
    </>
  )
}
