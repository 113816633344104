import { Form, Select } from "antd"
import { CustomInput, CustomTextArea } from "components/new-stuff/form"
import { useState } from "react"
import { surveyResponseTypes } from "."
import { Button } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useLocation } from "react-router-dom"

const CustomQuestionForm = ({
  formTitle,
  url,
  currTitle,
  currDescription,
  currResponseId,
  currResponseValue,
  invalidateQueries,
  onClose,
}: {
  formTitle: string
  url: string
  currTitle?: string
  currDescription?: string
  currResponseId?: string
  currResponseValue?: string
  invalidateQueries: () => void
  onClose: () => void
}) => {
  const [form] = Form.useForm()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const title = Form.useWatch("title", form)
  const description = Form.useWatch("description", form)
  const responseId = Form.useWatch("response", form)
  const [responseValue, setResponseValue] = useState(currResponseValue || "")
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `${url}&description=${title}&alias=${description}&response_type_id=${responseId}&response_option=${responseValue}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        invalidateQueries()
        form.resetFields()
        onClose()
        // setShowNew(false)
      } else {
      }
    },
  })
  const onFinish = (values: any) => {
    console.log(values)
    submit()
  }
  return (
    <div className="border border-neutral30 pb-2 rounded-5px ">
      <h6 className="py-3 font-circular text-padeBlack  text-base border-b px-4 font-medium">
        {formTitle}
      </h6>
      <div className="p-4">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          initialValues={{
            title: currTitle ?? "",
            description: currDescription ?? "",
            response: currResponseId ?? "",
          }}
        >
          <CustomInput
            label="Question title"
            name="title"
            required
          />
          <CustomTextArea
            label="Question description"
            name="description"
            required
          />
          <Form.Item
            label={
              <span className="font-circular text-neutral300 text-sm">
                Select response type
              </span>
            }
            name="response"
            rules={[
              {
                required: true,
                message: "Please enter this field!",
              },
            ]}
          >
            <Select
              showSearch
              options={surveyResponseTypes?.map((item) => ({
                label: item.title,
                value: item.id,
              }))}
              onChange={(value) => {
                console.log(value)

                const selected = surveyResponseTypes?.find(
                  (item) => item.id === value
                )
                console.log(selected)

                if (selected) {
                  setResponseValue(selected?.title)
                }
              }}
              filterOption={(input, option) => {
                if (option) {
                  const value = (option?.label as string) || ""
                  return (
                    value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  )
                } else {
                  return false
                }
              }}
              placeholder="Select response type"
            />
          </Form.Item>

          <div className="flex items-center justify-end gap-2 mt-2">
            <Button
              color="secondary"
              type="button"
              onClick={(e) => {
                e.preventDefault()
                onClose()
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              submitting={isLoading}
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                form.submit()
              }}
            >
              Confirm
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CustomQuestionForm
