import { ActionBarTab, Button, TopActionBar } from "components"
import React, { useState } from "react"

import { useLocation } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"

import Templates from "./Templates/Templates"
import Surveys from "./Surveys/Surveys"

const Survey: React.FC<{}> = () => {
  const myParam = useLocation().search
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const x = new URLSearchParams(myParam).get("x")
  const taxId = getIdFromKey(x || "")
  const { toast } = useAppNotificationStore()
  const [showNewTemplate, setShowNewTemplate] = useState(false)
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeleteId, setActiveDeleteId] = useState("")
  const [activeType, setActiveType] = useState("Surveys")

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`admin/performance/view/reviews?id=""&x=""`],
    })
  }

  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(
        `/admin/performance/remove_reviewtemplate?id=${activeDeleteId}`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        setOpenDeleteConfirmation(false)
        invalidateQueries()

        data.msg && toast.success(data.msg)
      } else {
        setOpenDeleteConfirmation(false)

        data.msg && toast.error(data.msg)
      }
    },
    onError: (data: any) => {
      setOpenDeleteConfirmation(false)

      data.msg && toast.error(data.msg)
    },
  })
  const handleClick = () => {
    if (showNewTemplate) {
      setShowNewTemplate(false)
    } else {
      setShowNewTemplate(true)
    }
  }
  const handleOpenDeleteModal = (id: string) => {
    setOpenDeleteConfirmation(true)
    setActiveDeleteId(String(id))
  }
  const handleDelete = () => {
    submitDelete()
  }

  return (
    <>
      <TopActionBar>
        <div className="h-full flex items-center gap-6">
          {["Surveys", "Templates"].map((item, index) => (
            <ActionBarTab
              key={index}
              action={() => setActiveType(item)}
              active={activeType == item}
              title={item}
            />
          ))}
        </div>
        <div>
          {activeType == "Surveys" ? (
            <Button
              color="primary"
              onClick={() => {
                // handleClick()
                navigate("/dashboard/survey/new")
              }}
            >
              Create Survey
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                // handleClick()
                navigate("/dashboard/survey/template")
              }}
            >
              Create Template
            </Button>
          )}
        </div>
      </TopActionBar>

      {activeType == "Surveys" && <Surveys />}
      {activeType == "Templates" && <Templates />}
    </>
  )
}
export default Survey
export const surveyTypes = [
  { survey_title: "General", survey_id: "1" },
  { survey_title: "Confirmation", survey_id: "2" },
  { survey_title: "Exit", survey_id: "3" },
]
