import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { useState } from "react"

import { Spin } from "antd"
import ViewGlobal from "./ViewGlobal"
import ViewCurrency from "./ViewCurrency"
import { useGet, usePost } from "utils/useFetch"
import { useLocation } from "react-router-dom"
import { ICurrencyInfo } from "./interface/payouts"
import { availableFlags } from "utils/flags"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"

const ViewPaymentDetails = () => {
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const location = useLocation()
  const { title } = location.state || {}
  const [activeCurrency, setActiveCurrency] = useState<ICurrencyInfo | null>(
    null
  )
  const { isLoading: isCurrenciesLoading, data: currenciesData } = useGet<
    ICurrencyInfo[]
  >({
    url: `admin/payment_schedules/view/currencies?id=${x}`,
    onSuccess: (data: any) => {
      {
        data.length > 1
          ? setActiveCurrency({
              currency_code: "Global",
              currency_symbol: "$",
              total: 0,
              count: 0,
            })
          : setActiveCurrency({
              currency_code: data[0]?.currency_code,
              currency_symbol: data[0]?.currency_symbol,
              total: data[0]?.total,
              count: data[0]?.count,
            })
      }
    },
  })
  const { isLoading: isCurrencyLoading, data: flagCurrencies } = usePost<
    ICurrencies[]
  >({
    url: "/commonutils/getcurrencies",
    body: {
      q: "",
      page: "",
    },
  })
  const totalSum =
    currenciesData?.reduce((sum, item) => sum + item.count, 0) || 0

  const displayedCurrencies = [
    ...(currenciesData && currenciesData?.length > 1
      ? [
          {
            currency_code: "Global",
            currency_symbol: "$",
            total: 0,
            count: totalSum,
          },
        ]
      : []),
    ...(currenciesData ?? []),
  ]

  return (
    <DashboardWrapper>
      {isCurrenciesLoading ? (
        <div className="h-screen flex justify-center items-center">
          <Spin spinning={isCurrenciesLoading} />
        </div>
      ) : (
        <div>
          <h6 className="text-sm text-padeBlack font-circular mb-3">
            Payment for {title}
          </h6>
          <div className="flex items-start justify-start gap-10 mb-[26px]">
            {displayedCurrencies?.map((curr, index) => (
              <button
                key={index}
                className={`py-[5px] px-[10px] border rounded-full flex  items-center gap-1 ${
                  activeCurrency?.currency_code === curr?.currency_code
                    ? "border-primary500 bg-[#E7ECF8]"
                    : "bg-white"
                }`}
                onClick={() => {
                  setActiveCurrency(curr)
                }}
              >
                <img
                  src={
                    availableFlags?.find(
                      (item) => item?.code === curr?.currency_code
                    )?.flag || ""
                  }
                  alt=""
                  className="h-6 w-6"
                />
                <p className="text-padeBlack text-sm font-circular">
                  {curr?.currency_code}
                </p>
                <p className="ml-2 lg:ml-[18px] px-2 py-0.5 rounded-5px text-xs bg-[#E7ECF8] font-avenir text-padeBlack">
                  {curr?.count}
                </p>
              </button>
            ))}
          </div>

          {activeCurrency?.currency_code === "Global" ? (
            <ViewGlobal currencies={currenciesData} />
          ) : activeCurrency?.currency_code !== null ? (
            <ViewCurrency
              activeCurrency={activeCurrency}
              currencies={flagCurrencies}
            />
          ) : null}
        </div>
      )}
    </DashboardWrapper>
  )
}

export default ViewPaymentDetails
