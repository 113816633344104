import { Form } from "antd"
import React, { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import getIdFromKey from "utils/getIdFromKey"

import { CustomQuestionCard } from "../component/QuestionCard"
import CustomQuestionForm from "../component/CustomQuestionForm"
import DeleteQuestion from "../modals/DeleteQuestion"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { Button } from "components"
import { ISurveyData, ITemplateBody } from "../interface"
import { useNavigate } from "react-router-dom"
import { useAppNotificationStore } from "stores"

export const AddSurveyQuestion: React.FC<{
  showNew: boolean
  setShowNew: Function
  surveyData: ISurveyData
  invalidateQueries: () => void
  x?: string | null
  isLoading: boolean
  activeTab: string
  setActiveTab: any
}> = ({
  showNew,
  setShowNew,
  surveyData,
  invalidateQueries,
  isLoading,
  x,
  activeTab,
  setActiveTab,
}) => {
  const [form] = Form.useForm()
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [activeDeletId, setActiveDeleteId] = useState("")

  const handleOpenDelete = (id: any) => {
    console.log(id)

    setOpenDeleteConfirmation(true)
    setActiveDeleteId(id)
  }

  const onSubmit = () => {
    if (x) {
      FinishSurvey({
        description: surveyData.survey.description,
        survey_id: x ? getIdFromKey(x || "") : "-1",
        survey_type_id: String(surveyData?.survey?.survey_type_id),
        survey_template_id: "",
        default_template: "no",
      })
    }
  }
  // FINISH SURVEY
  const { isLoading: isLoadingEdit, mutate: FinishSurvey } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/finish`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey`)

        data.msg && toast.success(data.msg)
      }
    },
  })
  return (
    <FormSectionWrapper
      form={form}
      isActive={activeTab == "2" ? true : false}
      title="Survey Questions"
      subtitle="Create questions to add to survey"
      showDropdown
      onDropDown={() => {
        setActiveTab("2")
      }}
      footerBtns={
        <div className="flex gap-3 justify-end">
          <Button
            color="secondary"
            onClick={() => navigate(-1)}
            type="button"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            title="Submit"
            onClick={onSubmit}
            submitting={isLoadingEdit}
          >
            Finish
          </Button>
        </div>
      }
    >
      <div className="flex  flex-col  gap-1 px-4 md:px-6 xl:px-[60px]">
        <span className="font-circular text-neutral300 text-sm  ">
          Survey questions
        </span>

        <div className="flex flex-col gap-4">
          {isLoading ? (
            <div className="w-full flex  justify-center">
              <LoadingIndicatorWhite />
            </div>
          ) : surveyData?.survey?.questions.length > 0 ? (
            surveyData?.survey?.questions?.map((quest, index) => (
              <CustomQuestionCard
                id={quest.survey_question_id}
                key={index}
                question_title={quest.description}
                question={quest.alias}
                response_type_id={String(quest?.review_response_type_id)}
                type={quest?.response_options}
                handleOpenDelete={handleOpenDelete}
                invalidateQueries={invalidateQueries}
                editUrl={`/admin/surveys/edit_question?id=${quest.survey_question_id}`}
              />
            ))
          ) : (
            <div className="h-10 flex justify-center items-center">
              No available survey question
            </div>
          )}
        </div>

        {showNew && (
          <CustomQuestionForm
            formTitle="Add Question"
            url={`/admin/surveys/add_question?id=${getIdFromKey(x || "")}`}
            invalidateQueries={invalidateQueries}
            onClose={() => setShowNew(false)}
          />
        )}
        {!showNew && (
          <button
            className="mt-6  font-medium  text-sm text-primary500 flex items-center gap-2 mb-3 underline"
            onClick={() => {
              setShowNew(!showNew)
            }}
          >
            <p>Add a question</p>
          </button>
        )}
        <DeleteQuestion
          title="Delete Question"
          open={openDeleteConfirmation}
          onClose={() => {
            setOpenDeleteConfirmation(false)
            setActiveDeleteId("")
          }}
          invalidateQueries={invalidateQueries}
          activeDeleteId={activeDeletId}
          url={`/admin/surveys/delete_question?id=${activeDeletId}`}
        />
      </div>
    </FormSectionWrapper>
  )
}
