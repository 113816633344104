import { Form, Spin } from "antd"
import React, { useState } from "react"
import { CustomInput } from "pages/dashboard/documents/custom-form"

import { Button, DashboardContainer } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"
import getIdFromKey from "utils/getIdFromKey"
import { AddTemplateQuestion } from "./AddTemplateQuestion"
import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { ISurveyTemplateData } from "../interface/templates"
import AntDesignSelect from "components/antd/AntDesignSelect"
import { surveyTypes } from "../Survey"
import { CustomTextArea } from "components/new-stuff/form"
import { ITemplateBody } from "../interface"

const FinishTemplate: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const [activeKey, setActiveKey] = useState<string>("1")

  const {
    isLoading: isDataLoading,
    data: templateData,
    refetch,
  } = useGet<ISurveyTemplateData>({
    url: `/admin/surveys/view/index?id=customize_template&x=${x}`,
    enabled: !!x,
  })
  const [showNew, setShowNew] = useState(false)

  const [errorMessage, setErrorMessage] = useState("")

  const invalidateQueries = () => {
    refetch()
  }

  const { isLoading: isLoadingCheck, mutate: check } = useMutation({
    mutationFn: async (body: string) => {
      const res = await axiosInstance.get(
        `/admin/surveys/checktemplate?description=${body}&survey_template_id=-1&survey_template_type_id=3`
      )
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
      } else {
        setErrorMessage("Template name is already taken")
      }
    },
  })
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: SurveyTemplateData) => {
      const res = await axiosInstance.post(`/admin/surveys/add_template`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        navigate(`/dashboard/survey/template?x=${data?.data}`)
        setActiveKey("2")

        {
          data.msg && toast.success(data.msg)
        }
      }
    },
  })
  const onFinish = (values: Values) => {
    const { title, description, type } = values

    submit({
      survey_template_id: x ? getIdFromKey(x || "") : "-1",
      survey_template_type_id: templateData
        ? String(templateData?.template?.survey_template_type_id)
        : type,
      description: title,
      alias: description,
    })
  }
  return (
    <DashboardContainer>
      {x && isDataLoading ? (
        <div className="h-[200px] items-center flex justify-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className="max-w-[1024px]   rounded-md space-y-[20px]">
          <FormSectionWrapper
            form={form}
            name=" Survey template"
            title=" Survey template"
            subtitle="Customize template of your survey "
            isActive={activeKey === "1" ? true : false}
            initialValues={{
              title: templateData?.template.description || "",
              description: templateData?.template.alias || "",
            }}
            layout="vertical"
            onFinish={onFinish}
            style={{ maxWidth: 1024 }}
            autoComplete="off"
            footerBtns={
              <div className="flex gap-3 items-center justify-end">
                <Button
                  color="secondary"
                  onClick={() => navigate("/dashboard/survey")}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  submitting={isLoading}
                >
                  Continue
                </Button>
              </div>
            }
          >
            <div className="flex flex-col  px-4 md:px-6 xl:px-[60px]">
              <div className="flex items-center gap-x-4">
                <CustomInput
                  label="Template name"
                  name="title"
                  required
                  className="w-full"
                  placeholder="Enter template name"
                  onBlur={(e) => {
                    check(e.target.value)
                  }}
                  extra={
                    errorMessage !== "" && (
                      <span className="text-danger400 text-sm">
                        {errorMessage}
                      </span>
                    )
                  }
                />
                {!templateData && (
                  <AntDesignSelect
                    name="type"
                    label="Survey type"
                    placeholder="Please select"
                    required={true}
                    options={
                      surveyTypes &&
                      surveyTypes?.map((surv) => ({
                        label: surv.survey_title,
                        value: surv.survey_id,
                      }))
                    }
                  />
                )}
              </div>

              <CustomTextArea
                label="Description"
                name="description"
                className="w-full"
              />
            </div>
          </FormSectionWrapper>

          {templateData && (
            <AddTemplateQuestion
              isLoading={isDataLoading}
              activeKey={activeKey === "2" ? true : false}
              setActiveKey={setActiveKey}
              showNew={showNew}
              setShowNew={setShowNew}
              surveyTemplateData={templateData}
              invalidateQueries={invalidateQueries}
              x={x}
            />
          )}
        </div>
      )}
    </DashboardContainer>
  )
}
export default FinishTemplate
interface Values {
  title: string
  description: string
  type: string
}
interface SurveyTemplateData {
  survey_template_id: string
  survey_template_type_id: string
  description: string
  alias: string
}
