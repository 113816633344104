import { DatePicker, Spin } from "antd"
import { CustomInput } from "components/new-stuff/form"
import { Button } from "components"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"
import { useLocation, useNavigate } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore, useAuthStore } from "stores"
import { queryClient } from "index"

const { RangePicker } = DatePicker

const BasicDetails = ({
  setCurrentPage,
  formBody,
  setFormBody,
  isActive,
  form,
  isLoadingEdit,
  Form,
  status,
}: {
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  formBody: any
  setFormBody: any
  isActive: boolean
  isLoadingEdit: boolean
  form: any
  Form: any
  status?: "not setup" | "completed" | "pending" | string
}) => {
  const { user } = useAuthStore()
  const EmployeeWalletID = user?.userWalletId
  const { toast } = useAppNotificationStore()
  const navigate = useNavigate()
  const { isLoading: isLoading, mutate: submit } = useMutation({
    mutationFn: async (body: IBody) => {
      const res: any = await axiosInstance.post(
        `${walletBaseUrl}/v1/wallets/${EmployeeWalletID}/basic-info`,
        body,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res?.data
    },
    onSuccess: (data: any) => {
      if (data.success) {
        setCurrentPage(2)
        queryClient.invalidateQueries({
          queryKey: [`kyc`],
        })
      }
    },
    onError: (data: any) => {
      {
        data.message && toast.error(data.message)
      }
    },
  })
  const secondary_email = Form.useWatch("secondary_email", form)
  const onFinish = () => {
    setFormBody({
      ...formBody,
      secondary_email,
    })
    // {
    //   secondary_email !== ""
    //     ? formBody?.secondary_email === secondary_email
    //       ? setCurrentPage(2)
    //       :
    submit({
      walletId: EmployeeWalletID ? EmployeeWalletID : "",
      alternateEmail: secondary_email,
      onboarded: true,
    })
    //     : setCurrentPage(2)
    // }
  }

  return (
    <FormSectionWrapper
      isActive={isActive}
      name="Tier 1 KYC (Basic Details)"
      title="Tier 1 KYC (Basic Details)"
      subtitle="No verification required. However, you have a daily withdrawal limit of up to N200,000"
      form={form}
      layout="vertical"
      showDropdown
      onDropDown={() => {
        isActive ? setCurrentPage(0) : setCurrentPage(1)
      }}
      status={status}
      footerBtns={
        <div className="flex justify-end items-center gap-4  ">
          <Button
            color="secondary"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={onFinish}
            submitting={isLoading}
          >
            Save
          </Button>
        </div>
      }
    >
      <div>
        {isLoadingEdit ? (
          <div className="flex justify-center items-center py-10">
            {" "}
            <Spin />
          </div>
        ) : (
          <div className="px-4 md:px-6 lg:px-8 xl:px-[60px] grid grid-cols-1 lg:grid-cols-2 lg:gap-x-10 items-start pb-4">
            <CustomInput
              label="First name "
              name="first_name"
              disabled
            />

            <CustomInput
              label="Last name "
              name="last_name"
              disabled
            />
            <CustomInput
              type="email"
              label="Company email"
              name="Company_email"
              disabled
            />
            <CustomInput
              type="email"
              label="Secondary email"
              name="secondary_email"
              placeholder="Your personal email"
              required
            />
          </div>
        )}
      </div>
    </FormSectionWrapper>
  )
}

export default BasicDetails
interface IBody {
  walletId: string
  alternateEmail: string
  onboarded: boolean
}
