import { Form, Spin } from "antd"
import React, { useState } from "react"
import { CustomInput } from "pages/dashboard/documents/custom-form"

import { Button, DashboardContainer } from "components"
import { useMutation } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { useAppNotificationStore } from "stores"

import { useLocation, useNavigate } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { useGet } from "utils/useFetch"

import { LoadingIndicatorWhite } from "components/custom/LoaderWhite"
import { AddSurveyQuestion } from "./AddSurveyQuestion"
import { ISurveyData, ITemplateBody } from "../interface"
import FormSectionWrapper from "components/new-stuff/form/FormSectionWrapper"

const FinishSurvey: React.FC<{}> = ({}) => {
  const [form] = Form.useForm()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const myParam = useLocation().search
  const x = new URLSearchParams(myParam).get("x")
  const [activeTab, setActiveTab] = useState("1")
  const [selectedTemplateId, setSelectedTemplateId] = useState("")

  const [showNew, setShowNew] = useState(false)
  const title = Form.useWatch("title", form)
  const {
    isLoading: isEmployeeLoading,
    data: templateData,
    isFetching: isFetchingEmpData,
  } = useGet<ISurveyData>({
    url: `/admin/surveys/view/index?id=customize&x=${x}&s=`,
    enabled: !!x,
    onSuccess: (data: any) => {
      console.log(data)
      form.setFieldValue("title", data?.survey?.description)
      data?.survey?.questions && data?.survey?.questions.length > 0
        ? setShowNew(false)
        : setShowNew(true)
    },
  })
  // EDIT SURVEY TITLE
  const { isLoading: isLoadingEdit, mutate: EditSurvey } = useMutation({
    mutationFn: async (body: ITemplateBody) => {
      const res = await axiosInstance.post(`/admin/surveys/add`, body)
      return res.data
    },
    onSuccess: (data) => {
      if (data) {
        data.msg && toast.success(data.msg)
        setActiveTab("2")
      }
    },
  })

  const invalidateQueries = () => {
    queryClient.invalidateQueries({
      queryKey: [`/admin/surveys/view/index?id=customize&x=${x}&s=`],
    })
  }

  const onSubmit = () => {
    if (x) {
      EditSurvey({
        description: title,
        survey_id: String(templateData?.survey?.survey_id) || "",
        survey_type_id: String(templateData?.survey?.survey_type_id),
        survey_template_id: selectedTemplateId,
        default_template: "no",
      })
    }
  }

  return (
    <DashboardContainer>
      {x && isEmployeeLoading ? (
        <div className="h-[200px] items-center flex justify-center">
          <LoadingIndicatorWhite />
        </div>
      ) : (
        <div className="max-w-[1024px]   rounded-md space-y-[20px]">
          <FormSectionWrapper
            name={`Finish "${templateData?.survey?.description}" ${templateData?.survey?.survey_type} survey`}
            title={`Finish "${templateData?.survey?.description}" ${templateData?.survey?.survey_type} survey`}
            subtitle="Once it has a title, you can continue setting it up "
            isActive={activeTab == "1" ? true : false}
            initialValues={{
              title: templateData?.survey?.description || "",
            }}
            showDropdown
            onDropDown={() => {
              setActiveTab("1")
            }}
            layout="vertical"
            form={form}
            // onFinish={onFinish}
            style={{ maxWidth: 1024 }}
            autoComplete="off"
            footerBtns={
              <div className="flex gap-3 justify-end">
                <Button
                  color="secondary"
                  onClick={() => navigate(-1)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  title="Submit"
                  onClick={onSubmit}
                  submitting={isLoadingEdit}
                >
                  Submit
                </Button>
              </div>
            }
            className="font-avenir"
          >
            <>
              <div className="grid grid-cols-2 gap-x-6  px-4 md:px-6 xl:px-[60px]">
                <div className="col-span-2">
                  <CustomInput
                    label="Survey title"
                    name="title"
                    required
                    className="w-full"
                    // onBlur={(e) => {
                    //   check(e.target.value)
                    // }}
                  />
                </div>
              </div>
            </>
          </FormSectionWrapper>

          {templateData && (
            <AddSurveyQuestion
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              x={x}
              isLoading={isFetchingEmpData}
              showNew={showNew}
              setShowNew={setShowNew}
              surveyData={templateData}
              invalidateQueries={invalidateQueries}
            />
          )}
        </div>
      )}
    </DashboardContainer>
  )
}
export default FinishSurvey
