import { useQuery } from "@tanstack/react-query"
import { useLocation, useSearchParams } from "react-router-dom"
import { IGetReportSummary } from "../typings"
import { axiosInstance } from "utils/request"
import { Spin, Table } from "antd"
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline"
import { useAuthStore } from "stores"
import { formatCurrencyNoSymbol } from "utils/currency"
import { TextLink } from "components"
import { isPadeDomain } from "utils/utils"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import Papa from "papaparse"

const PayRollReportSummary = () => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const { pathname } = useLocation()
  // const id = getIdFromKey(searchParams.get("key") || "")
  const { user } = useAuthStore()

  const { isLoading: isGetPayrollSummaryLoading, data: payrollSummary } =
    useQuery<IGetReportSummary>({
      queryKey: [
        `${
          pathname.includes("approval") ? "" : "/admin"
        }/payrolls/view/report_summary`,
        id,
      ],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${
            pathname.includes("approval") ? "" : "/admin"
          }/payrolls/view/report_summary`,
          {
            params: new URLSearchParams({
              id: id || "",
            }),
          }
        )
        return res.data
      },
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
      onSuccess(data) {
        mixPanelEvent("payroll-report-summary-listing")
      },
    })

  if (isGetPayrollSummaryLoading) {
    return (
      <div
        className="fixed inset-0 grid place-content-center bg-white z-50"
        style={{
          zIndex: 99999,
        }}
      >
        <Spin size="large" />
      </div>
    )
  }

  const allowancesData =
    payrollSummary?.payrollReportSummaryAllowances?.map((item) => ({
      title: item.description,
      dataIndex: item.description,
      key: item.description,
      render: (text: any, record: any) => {
        const earning = payrollSummary.payrollReportSummaryEarnings?.find(
          (earning) =>
            earning.person_id === record.person_id &&
            earning.description === item.description
        )

        const currency = payrollSummary?.payrollReportSummaries?.find(
          (item) => item.person_id === record.person_id
        )?.currency_symbol

        return earning ? (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {currency} {formatCurrencyNoSymbol(Number(earning.amount || ""))}
          </span>
        ) : (
          ""
        )
      },
    })) || []

  const trueBenefits = filterPropertiesWithTrue(
    payrollSummary && payrollSummary?.payrollReportSummaryValues
      ? payrollSummary?.payrollReportSummaryValues[0]
      : {}
  )

  const benefitsData: {
    title: string
    dataIndex: string
    key: string
    render: (text: any, record: any) => JSX.Element
  }[] = []

  const payrollSummaryValues = payrollSummary?.payrollReportSummaryValues

  Object.keys(trueBenefits).forEach((key) => {
    const dataIndex = key.replace("has_", "")
    const title = generateTitle(dataIndex)

    payrollSummaryValues?.forEach((item) => {
      benefitsData.push({
        title,
        dataIndex,
        key: dataIndex,
        render: (text, record) => {
          return record[dataIndex] ? (
            <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
              {record.currency_symbol}{" "}
              {formatCurrencyNoSymbol(Number(record[dataIndex] || ""))}
            </span>
          ) : (
            <></>
          )
        },
      })
    })
  })

  const TableColumns = [
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Employee Number",
      dataIndex: "identification_number",
      key: "identification_number",
    },
    {
      title: "Role",
      dataIndex: "employee_title",
      key: "employee_title",
    },
    {
      title: "Hire Date",
      dataIndex: "hire_date",
      key: "hire_date",
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Paygrade",
      dataIndex: "paygrade",
      key: "paygrade",
    },
    {
      title: "Payband",
      dataIndex: "payband",
      key: "payband",
    },
    ...allowancesData,
    {
      title: "Other Payables",
      dataIndex: "payable",
      key: "payable",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.currency_symbol}{" "}
            {formatCurrencyNoSymbol(Number(record.payable || ""))}
          </span>
        )
      },
    },
    {
      title: "Gross Pay",
      dataIndex: "gross",
      key: "gross",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.currency_symbol}{" "}
            {formatCurrencyNoSymbol(Number(record.gross || ""))}
          </span>
        )
      },
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.currency_symbol}{" "}
            {formatCurrencyNoSymbol(Number(record.tax || ""))}
          </span>
        )
      },
    },
    ...benefitsData,
    {
      title: "Total Deductions",
      dataIndex: "deduction",
      key: "deduction",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.currency_symbol}{" "}
            {formatCurrencyNoSymbol(Number(record.deduction || ""))}
          </span>
        )
      },
    },
    {
      title: "Net Pay",
      dataIndex: "net",
      key: "net",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.currency_symbol}{" "}
            {formatCurrencyNoSymbol(Number(record.net || ""))}
          </span>
        )
      },
    },
    {
      title: "Days Worked",
      dataIndex: "days_worked",
      key: "days_worked",
      render: (text: any, record: any) => {
        return (
          <span className={"font-avenir text-neutral500 whitespace-nowrap"}>
            {record.days_worked}/{record.total_days}
          </span>
        )
      },
    },
  ]

  const TableData =
    payrollSummary?.payrollReportSummaries?.map((summary) => {
      const rowData: any = { ...summary }
      payrollSummary.payrollReportSummaryAllowances?.forEach((allowance) => {
        const earning = payrollSummary.payrollReportSummaryEarnings?.find(
          (earning) =>
            earning.person_id === summary.person_id &&
            earning.description === allowance.description
        )
        rowData[allowance.description] = earning ? earning.amount : null
      })
      return rowData
    }) || []

  const handleDownloadCSV = () => {
    const headers = TableColumns.map((col) => col.title) // Extract column titles as headers
    const data = TableData.map((row) =>
      TableColumns.reduce((acc: any, col) => {
        acc[col.title] = row[col.dataIndex]
        return acc
      }, {})
    )

    const csv = Papa.unparse({ fields: headers, data })
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" })
    const link = document.createElement("a")
    const url = URL.createObjectURL(blob)
    link.setAttribute("href", url)
    link.setAttribute(
      "download",
      `${payrollSummary?.report_download_title}.csv`
    )
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div
      className="fixed inset-0 overflow-y-auto bg-white z-50"
      style={{
        zIndex: 99999,
      }}
    >
      <div className="py-14 px-6 xl:px-16">
        <div className="border shadow-xl">
          {/* ========Download Button======== */}
          {/* ========Download Button======== */}
          <div className="flex justify-end p-6 mb-4 bg-neutral20">
            <button
              className="px-4 py-2 font-avenir !text-sm text-a11y rounded-md bg-primary500 flex items-center"
              onClick={() => {
                handleDownloadCSV()
                // exportTableToCSV(
                //   `${payrollSummary?.report_download_title}.csv`,
                //   `
                // ${payrollSummary?.qualifiedPayrollDate} Payroll. Period: ${payrollSummary?.payPeriod}
                // `
                // )
              }}
            >
              <DocumentArrowDownIcon className="w-5 h-5 mr-1" />
              <span>CSV</span>
            </button>
          </div>

          {/* ========Table Header======== */}
          {/* ========Table Header======== */}
          <div className=" px-6">
            <h1 className="text-xl font-avenir text-neutral600 font-medium">
              Payroll Summary Report{" "}
              {payrollSummary?.payrollStatusId &&
              payrollSummary?.payrollStatusId < 4
                ? "(Draft)"
                : ""}
            </h1>
            <hr className="my-4" />

            <span className="text-neutral500 font-avenir ">
              {" "}
              Report created on {payrollSummary?.report_date}{" "}
            </span>
          </div>
          <div className="flex flex-col items-end my-4 px-6 text-neutral500 text-sm font-avenir">
            <span> {user?.userOrganisation} </span>
            <span> {payrollSummary?.officeAddress?.address_line_1} </span>
            <span>
              {" "}
              {payrollSummary?.officeAddress?.address_city}{" "}
              {payrollSummary?.officeAddress?.address_state}{" "}
              {payrollSummary?.officeAddress?.zip_code}{" "}
              {payrollSummary?.officeAddress?.country}{" "}
            </span>
          </div>
          <div className=" my-4 px-6 text-neutral500 text-sm font-avenir">
            <span className="font-semibold">
              {payrollSummary?.qualifiedPayrollDate} Payroll.
            </span>{" "}
            Period: {payrollSummary?.payPeriod}
          </div>

          <div className="px-6">
            {/* =======Table====== */}
            {/* =======Table====== */}
            <Table
              loading={isGetPayrollSummaryLoading}
              dataSource={TableData}
              columns={TableColumns}
              pagination={false}
              scroll={{
                y: "calc(100vh - 300px)",
                x: "max-content",
              }}
              bordered
              className="[&_thead_*]:!font-avenir [&_tbody_*]:!font-avenir [&_tbody_*]:!text-neutral500"
            />
          </div>
        </div>
        {isPadeDomain() === true ? (
          <div className="mt-10 flex justify-center text-neutral500 text-sm font-avenir ">
            © {new Date().getFullYear()} PaidHR.{" "}
            <TextLink
              to="https://paidhr.com/terms"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary500 text-sm !no-underline mx-1"
              title="Terms of Service"
            >
              Terms
            </TextLink>{" "}
            &{" "}
            <TextLink
              to="https://paidhr.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary500 text-sm !no-underline mx-1"
              title="Privacy Policy"
            >
              Privacy
            </TextLink>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default PayRollReportSummary

const generateTitle = (dataIndex: string) => {
  let title = dataIndex

  if (dataIndex.includes("deduction")) {
    title = title
      .replace("_deduction", " Employee")
      .replace("assurance", "Assurance")
      .replace("health_insurance", "NHIS")
  } else if (dataIndex.includes("contribution")) {
    title = title
      .replace("_contribution", " Employer")
      .replace("assurance", "Assurance")
      .replace("health_insurance", "NHIS")
  }

  // Capitalize the first letter of the title
  title = title.replace(/^\w/, (c) => c.toUpperCase())

  return title
    .replace(/_/g, " ")
    .replace("Benefit", "Benefits")
    ?.replace("hmo", "HMO")
    ?.replace("Other Employee", "Other Deductions")
    ?.replace("Housing Employee", "NHF Employee")
    ?.replace("Housing Employer", "NHF Employer")
}

function filterPropertiesWithTrue(obj: { [key: string]: any }): {
  [key: string]: boolean
} {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] === "boolean" && obj[key] === true) {
      acc[key] = obj[key]
    }
    return acc
  }, {} as { [key: string]: boolean })
}

// function downloadCSV(csv: any, filename: any) {
//   var csvFile
//   var downloadLink
//   // CSV file
//   csvFile = new Blob([csv], { type: "text/csv" })
//   //  Download link
//   downloadLink = document.createElement("a")
//   downloadLink.download = filename
//   downloadLink.href = window.URL.createObjectURL(csvFile)
//   downloadLink.style.display = "none"
//   document.body.appendChild(downloadLink)
//   downloadLink.click()
// }

// function exportTableToCSV(filename: any, header: string) {
//   var csv = []
//   // add the header row
//   csv.push(header)

//   // get all table rows
//   var rows = document.querySelectorAll("table tr")
//   // loop over each table row and populate array
//   for (var i = 0; i < rows.length; i++) {
//     var row = [],
//       //  get each column in the row
//       cols = rows[i].querySelectorAll("td, th")
//     for (var j = 0; j < cols.length; j++) {
//       // check if column is a div and if so , remove it
//       if (cols[j].querySelector("div")) {
//         //  remove div
//         cols[j].querySelector("div")?.remove()
//       } else if (cols[j].querySelector("span")) {
//         row.push(
//           cols[j]
//             ?.querySelector("span")
//             ?.innerHTML.replace(/,/g, "")
//             .replace(/₦/g, "")
//         )
//       } else row.push(cols[j].innerHTML.replace(/,/g, "").replace(/₦/g, "")) //  push column text into array
//     }
//     // join each row with a comma
//     csv.push(row.join(","))
//   }

//   downloadCSV(csv.join("\n"), filename)
// }
