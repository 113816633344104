import { useState } from "react"
import { formatCurrencyNoSymbol } from "utils/currency"
import Balance from "../../../assets/images/balance_icon.svg"
import TotalCredit from "../../../assets/images/total_credit_icon.svg"
import TotalDebit from "../../../assets/images/total_debit.svg"

import { Button } from "components"
import { useAuthStore } from "stores"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { walletBaseUrl, walletPublicKey } from "utils/newbaseurl"
import { useQuery } from "@tanstack/react-query"
import { axiosInstance } from "utils/request"
import { formatDateReadable, formatTimeReadble } from "utils/formatDate"
import { StatsCard } from "../documents/company-details/Cards"
import { DatePicker, Table } from "antd"

import {
  INewTransactionData,
  StatsData,
  StatsResponse,
} from "./interface/statement"
import dayjs, { Dayjs } from "dayjs"
import { CSVLink } from "react-csv"
import { IWalletPade } from "./interface/wallet"
import { availableFlags } from "utils/flags"
import DashboardWrapper from "components/new-stuff/ui/DashboardWrapper"
import { rearrangeBalances } from "./crossBorderPayments/Overview"

const Statements = () => {
  const { RangePicker } = DatePicker

  const { user } = useAuthStore()
  const walletId = user?.organisationWalletId

  const [startDate, setStartDate] = useState(
    dayjs(new Date()).subtract(7, "day").format("YYYY/MM/DD") || ""
  )
  const [endDate, setEndDate] = useState(
    dayjs(new Date())?.format("YYYY/MM/DD") || ""
  )

  const [isCustom, setIsCustom] = useState(false)
  const [activePeriod, setActivePeriod] = useState<dateData>({
    id: "1",
    period: "One Week",
    startDate: "",
    endDate: "",
  })
  const [activeCurrency, setActiveCurrency] = useState<any>({
    currency: "NGN",
    balance: 0,
    symbol: "₦",
    _id: "",
  })

  const {
    isLoading: isStatementLoading,
    data: statementData,
    refetch: refetchStatement,
  } = useQuery<INewTransactionData>({
    queryKey: [`transactions`, startDate, endDate, activeCurrency],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/transactions?limit=0&startDate=${
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : ""
        }&endDate=${endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""}${
          activeCurrency !== undefined
            ? `&currency=${activeCurrency?.currency}`
            : ""
        }`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      mixPanelEvent("wallet-statement-listing")
    },
  })
  const {
    isLoading: isStatementStatsLoading,
    data: statementStatsData,
    refetch: refetchStatsStatement,
  } = useQuery<StatsResponse>({
    queryKey: [`v1/wallets/stats`],
    queryFn: async () => {
      const res = await axiosInstance.get(
        `${walletBaseUrl}/v1/wallets/${walletId}/stats`,
        {
          headers: {
            api_key: walletPublicKey,
          },
        }
      )
      return res.data
    },
    onSuccess: (data: any) => {
      mixPanelEvent("wallet-statement-listing")
    },

    // keepPreviousData: true,
    // refetchOnWindowFocus: false,
    // cacheTime: 24 * 60 * 60 * 1000,
  })
  const { isLoading: isLoadingOverview, data: newWalletData } =
    useQuery<IWalletPade>({
      queryKey: [`${walletBaseUrl}/v1/wallets/${walletId}`],
      queryFn: async () => {
        const res = await axiosInstance.get(
          `${walletBaseUrl}/v1/wallets/${walletId}?populate=virtualAccounts`,
          {
            headers: {
              api_key: walletPublicKey,
            },
          }
        )
        return res.data
      },

      // keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 24 * 60 * 60 * 1000,
    })

  const StatementTableData =
    statementData?.data?.docs?.map((item: any) => {
      const currencyInfo = availableFlags.find(
        (flag) => flag.code === item.currency
      )
      return {
        key: item._id,
        date: {
          date: formatDateReadable(String(item?.createdAt)),
          time: formatTimeReadble(String(item?.createdAt)),
        },
        amount: {
          value: item.amount,
          code: item.type,
          symbol: currencyInfo?.symbol,
        },
        before: `${currencyInfo?.symbol} ${formatCurrencyNoSymbol(
          Number(item?.previousBalance)
        )}`,
        after: `${currencyInfo?.symbol} ${formatCurrencyNoSymbol(
          Number(item?.newBalance)
        )}`,
        type: item.type,
        reference: item.reference,
        remark: item.description,
      }
    }) || []

  const StatementTableColumns =
    [
      {
        title: "Date",
        dataIndex: "date",
        render: (item: { date: string; time: string }) => (
          <div className="whitespace-nowrap flex flex-col ">
            <span className={` font-avenir text-sm text-[#42526D]`}>
              {item.date},
            </span>
            <span className={` font-avenir text-xs text-neutral300`}>
              {item.time}
            </span>
          </div>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        filters:
          ["Debit", "Credit"]?.map((item) => ({
            text: item,
            value: item,
          })) || [],
        onFilter: (value: any, record: any) => {
          return record.type === value.toLowerCase()
        },
        render: (amount: { value: string; code: string; symbol: string }) => (
          <div
            className={`font-avenir text-sm ${
              amount.code === "debit" ? "text-danger500" : "text-green"
            }`}
          >
            <p>
              {amount?.symbol}
              {formatCurrencyNoSymbol(Number(amount.value))}
            </p>
          </div>
        ),
      },

      {
        title: "Previous Balance",
        dataIndex: "before",
        render: (before: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] flex-nowrap whitespace-nowrap`}
          >
            <p>{before}</p>
          </div>
        ),
      },
      {
        title: "New Balance",
        dataIndex: "after",
        render: (after: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] flex-nowrap whitespace-nowrap`}
          >
            <p>{after}</p>
          </div>
        ),
      },
      {
        title: "Reference",
        dataIndex: "reference",
        render: (reference: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[150px] overflow-hidden`}
          >
            <p>{reference}</p>
          </div>
        ),
      },
      {
        title: "Remark",
        dataIndex: "remark",
        render: (remark: string) => (
          <div
            className={`font-avenir text-sm text-[#42526D] w-[170px] overflow-hidden`}
          >
            <p>{remark}</p>
          </div>
        ),
      },
    ] || []
  const findCurrency = (
    currencyCode: string,
    response: StatsResponse | undefined
  ): StatsData | undefined => {
    return response?.data?.[currencyCode as keyof typeof response.data]
  }
  const currencyBalance = newWalletData?.data?.balances?.find(
    (balance) => balance.currency === activeCurrency?.currency
  )
  const currencyInfo = findCurrency(
    activeCurrency?.currency,
    statementStatsData
  )

  const cardData = [
    {
      id: "balance",
      icon: Balance,
      title: "Balance",
      value: ` ${currencyBalance?.symbol ?? ""}  ${
        currencyBalance?.balance
          ? formatCurrencyNoSymbol(Number(currencyBalance?.balance) || 0)
          : 0
      }`,
    },
    {
      id: "Total credit",
      icon: TotalCredit,
      title: "Total Credit",
      value: ` ${currencyBalance?.symbol}  ${
        currencyInfo?.credit
          ? formatCurrencyNoSymbol(Number(currencyInfo?.credit) || 0)
          : 0
      }`,
    },
    {
      id: "Total debit",
      icon: TotalDebit,
      title: "Total Debit",
      value: ` ${currencyBalance?.symbol}  ${
        currencyInfo?.debit
          ? formatCurrencyNoSymbol(Number(currencyInfo?.debit) || 0)
          : 0
      }`,
    },
  ]
  const periods: dateData[] = [
    {
      id: "1",
      period: "One Week",
      startDate: "",
      endDate: "",
    },
    {
      id: "2",
      period: "One Month",
      startDate: "",
      endDate: "",
    },

    {
      id: "3",
      period: "Custom",
      startDate: "",
      endDate: "",
    },
  ]
  const headers = [
    // { label: "Date", key: "createdAt" },
    { label: "Date", key: "createdAt" },
    { label: "Amount", key: "amount" },
    { label: "Type", key: "type" },
    { label: "Previous Balance", key: "previousBalance" },
    { label: "New Balance", key: "newBalance" },
    { label: "Reference", key: "reference" },
    { label: "Remark", key: "description" },
  ]

  const csvProps = {
    filename: "statement.csv",
    headers: headers,
    data: statementData?.data?.docs || [],
  }

  return (
    <DashboardWrapper>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between w-full items-center">
          <div>
            <h5 className="text-lg font-bold  text-padeBlack font-circular">
              Transactions
            </h5>
            <p className="text-sm text-bluesubtext">
              This report covers all international transactions, providing
              insights into cash flow, currency conversions.{" "}
            </p>
          </div>{" "}
          <div>
            <CSVLink {...csvProps}>
              <Button
                color="primary"
                title="Download"
              >
                Download
              </Button>
            </CSVLink>
          </div>
        </div>
        <div className="flex items-start justify-start gap-10">
          {newWalletData?.data?.balances &&
            rearrangeBalances(newWalletData?.data?.balances)?.map(
              (curr, index) => (
                <button
                  key={index}
                  className={`py-[5px] px-[10px] border rounded-full flex items-center gap-1 bg-white ${
                    activeCurrency?.currency === curr?.currency &&
                    "border-primary500"
                  }`}
                  onClick={() => {
                    if (curr?.currency) {
                      setActiveCurrency(curr)
                    }
                  }}
                >
                  <img
                    src={
                      availableFlags?.find(
                        (item) => item?.code === curr?.currency
                      )?.flag || ""
                    }
                    alt=""
                    className="h-6 w-6"
                  />
                  <p className="text-padeBlack text-sm font-circular">
                    {curr?.currency || "N/A"}{" "}
                    {/* Fallback if currency is undefined */}
                  </p>
                </button>
              )
            )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 ">
          {cardData.map((card, idx) => (
            <StatsCard
              id={card.id}
              key={idx}
              text={card.value}
              image={card.icon}
              title={card.title}
            />
          ))}
        </div>
        <div className=" bg-white my-4 px-4 pt-5">
          <div className="flex gap-2 justify-end items-center mb-4">
            <div className="bg-[#F3F5FB] rounded-sm cursor-pointer flex ">
              {periods?.map((it: dateData) => (
                <div
                  onClick={() => {
                    if (it?.id === "1") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(7, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else if (it?.id === "2") {
                      setStartDate(
                        dayjs(new Date())
                          .subtract(30, "day")
                          .format("YYYY/MM/DD") || ""
                      )
                      setEndDate(dayjs(new Date())?.format("YYYY/MM/DD") || "")
                    } else {
                      setIsCustom(true)
                      setActivePeriod({
                        id: "3",
                        period: "Custom",
                        startDate: "",
                        endDate: "",
                      })
                      return
                    }
                    setActivePeriod(it)
                    setIsCustom(false)
                  }}
                  key={it?.id}
                  className={`${
                    it.id === activePeriod?.id && "bg-primary500 text-white"
                  } whitespace-nowrap text-[#42526D] h-full w-full p-2 rounded-md`}
                >
                  {it?.period}
                </div>
              ))}
            </div>
            {isCustom && (
              <div className="flex gap-2">
                <RangePicker
                  format="YYYY/MM/DD"
                  placeholder={[" Start Date", "End Date"]}
                  onChange={(
                    dates: null | (Dayjs | null)[],
                    dateStrings: string[]
                  ) => {
                    if (dates) {
                      setStartDate(dateStrings[0])
                      setEndDate(dateStrings[1])
                    } else {
                    }
                  }}
                  className=" w-full sm:w-[250px] rounded-[5px]  text-neutral600 border  border-neutral50  h-full bg-white"
                  disabledDate={(date) => {
                    const endDate = dayjs()

                    const afterEndDate = date.isAfter(endDate)

                    return afterEndDate
                  }}
                />
              </div>
            )}
          </div>
          <Table
            columns={StatementTableColumns}
            dataSource={StatementTableData}
            loading={isStatementLoading}
            className="text-[#42526D]"
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
          />
        </div>
      </div>
    </DashboardWrapper>
  )
}

export default Statements
export interface dateData {
  id: string
  period: string
  startDate: string
  endDate: string
}
