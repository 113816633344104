export const surveyResponseTypes = [
  {
    id: "1",
    title: "Rating scale",
  },
  {
    id: "2",
    title: "Text box",
  },
  {
    id: "3",
    title: "Text box and Rating scale",
  },
]

export const allCoreValues = [
  {
    id: "1",
    title: "Honesty",
  },
  {
    id: "2",
    title: "Integrity",
  },
  {
    id: "3",
    title: "Respect",
  },
  {
    id: "4",
    title: "Humility",
  },
]
