import { useMutation } from "@tanstack/react-query"
import { Button } from "components"
import CustomModal from "components/new-stuff/ui/modals/CustomModal"
import React from "react"
import { useAppNotificationStore } from "stores"
import { axiosInstance } from "utils/request"

const DeleteSurvey = ({
  title,
  description,
  url,
  open,
  onClose,
  activeDeleteId,
  invalidateQueries,
}: {
  title: string
  description: string
  url: string
  open: boolean
  onClose: () => void
  invalidateQueries: () => void
  activeDeleteId: string
}) => {
  const { toast } = useAppNotificationStore()
  const { isLoading: isSubmittingDelete, mutate: submitDelete } = useMutation({
    mutationFn: async () => {
      const res = await axiosInstance.post(`${url}?id=${activeDeleteId}`)
      return res.data
    },
    onSuccess: (data) => {
      if (data.status !== "error") {
        onClose()
        invalidateQueries()
        data.msg && toast.success(data.msg)
      } else {
        onClose()
        data.msg && toast.success(data.msg)
      }
    },
    onError: (data: any) => {
      onClose()
      data.msg && toast.success(data.msg)
    },
  })
  const handleDelete = () => {
    submitDelete()
  }
  return (
    <CustomModal
      title={title}
      onCancel={onClose}
      open={open}
    >
      <div className=" py-3 flex flex-col ">
        <p className="text-[#15294B] text-sm  mb-6">{description}</p>
      </div>
      <div className=" items-center flex gap-4  justify-end ">
        <Button
          color="secondary"
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          submitting={isSubmittingDelete}
          onClick={() => {
            handleDelete()
          }}
        >
          Confirm
        </Button>
      </div>
    </CustomModal>
  )
}

export default DeleteSurvey
