import {
  ChevronDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline"
import { useMutation } from "@tanstack/react-query"
import {
  Checkbox,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tooltip,
} from "antd"
import { Button } from "components"
import { mixPanelEvent } from "components/new-stuff/mix-panel/MixPanel"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAppNotificationStore, useAuthStore } from "stores"
import { axiosInstance } from "utils/request"
import { IEmployee } from "../contractor/MultipleContractor"
import { useGet } from "utils/useFetch"
import { AntDesignRadio } from "components/antd/AntDesignRadio"
import usePermissions from "hooks/usePermissions"
import { ICurrencies } from "pages/dashboard/wallets/integration/interface/kyc"

const MultipleEmployee = ({
  setFormBodyMultipleEmp,
  formBodyMultipleEmp,
  setFormBodyMultipleCont,
  formBodyMultipleCont,
  currencies,
}: {
  setFormBodyMultipleEmp: any
  formBodyMultipleEmp: any
  setFormBodyMultipleCont: any
  formBodyMultipleCont: any
  currencies: ICurrencies[] | undefined
}) => {
  const navigate = useNavigate()
  const { toast } = useAppNotificationStore()
  const { user } = useAuthStore()
  const [form] = Form.useForm()
  const includeExitedValue = Form.useWatch("includeExited", form)
  const [canCreate] = usePermissions({
    allowedPermissions: ["can_create_pay_schedules"],
  })
  const description = Form.useWatch("description", form)
  const [nameError, setNameError] = useState("")
  const [requiredAmountError, setRequiredAmountError] = useState(false)
  const [activeMultiplePersonEmp, setActiveMultiplePersonEmp] = useState("")
  const [activeMultiplePersonCont, setActiveMultiplePersonCont] = useState("")
  const [addContToPayment, setAddContToPayment] = useState(false)
  const [scheduleConfirmation, setScheduleConfirmation] = useState(false)
  const { isLoading: isCheckingScheduleName, mutate: checkScheduleName } =
    useMutation({
      mutationFn: async (body: any) => {
        const res = await axiosInstance.get(
          `/admin/payment_schedules/check_custom_payment_schedule?description=${body.description}&payment_schedule_id=${body.id}`
        )
        return res.data
      },
      onSuccess: (data) => {
        if (data.status === "error" && data.msg) {
          setNameError(data.msg)

          toast.error(data.msg)
        }
      },
      onError: (data: any) => {
        setNameError(data.msg)
        toast.error(data.msg)
      },
    })
  const { isLoading: isSubmitting, mutate: submit } = useMutation({
    mutationFn: async (body: any) => {
      const res = await axiosInstance.post(
        "/admin/payment_schedules/create_custom_payment_schedule",
        body
      )
      return res.data
    },
    onSuccess: (data) => {
      {
        data.msg && toast.success(data.msg)
      }
      mixPanelEvent("pay-schedule-custom-add-success")
      navigate("/dashboard/payment-schedules/custom")
    },
    onError: (data: any) => {
      {
        data.msg && toast.error(data.msg)
      }
    },
  })
  const { isLoading: isEmployeeLoading, data: employees } = useGet<IEmployee[]>(
    {
      url: `/admin/people/getforselectionwithaccountdetails?active=${!includeExitedValue}`,
    }
  )
  const { isLoading: isContractorsLoading, data: contractors } = useGet<any[]>({
    url: `/admin/contractors/getforselectionwithaccountdetails?search=""`,
  })
  const handleCreateMultiplePayment = () => {
    const found = formBodyMultipleEmp?.persons?.find(
      (per: any, idx: any) => per.amount == ""
    )

    if (found) {
      setRequiredAmountError(true)
    } else {
      const multipleBodyEmp = formBodyMultipleEmp.persons?.map(
        (per: any, index: any) => {
          return {
            description: formBodyMultipleEmp.description,
            person_id: String(per.person_id),
            contractor_id: "",
            amount: String(per.amount),
            au_comment: "",
            state: "",
            source_currency_code:
              per?.currency_code === "USD" &&
              per?.source_currency_code === "USD"
                ? ""
                : per?.source_currency_code === "USD"
                ? per?.source_currency_code
                : "",
          }
        }
      )
      const multipleBodyCont = formBodyMultipleCont.persons?.map(
        (per: any, index: any) => {
          return {
            description: formBodyMultipleEmp.description,
            contractor_id: String(per.contractor_id),
            person_id: "",
            amount: String(per.amount),
            au_comment: "",
            state: "",
            source_currency_code:
              per?.currency_code === "USD" &&
              per?.source_currency_code === "USD"
                ? ""
                : per?.source_currency_code === "USD"
                ? per?.source_currency_code
                : "",
          }
        }
      )

      const updatedBody = [...multipleBodyEmp, ...multipleBodyCont]

      submit(updatedBody)
    }
  }

  return (
    <Form
      layout="vertical"
      form={form}
    >
      <div className=" mt-6 flex flex-col w-full  gap-y-4">
        <div
          className=""
          id="custom-payment-desc"
        >
          {" "}
          <Form.Item
            className="!mb-0"
            name="description"
            label={
              <span className="font-circular text-padeBlack text-sm  flex items-center">
                Payment Description
                <Tooltip
                  title="Mamixum of 50 characters"
                  trigger={["hover"]}
                >
                  <span>
                    <ExclamationCircleIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
                  </span>
                </Tooltip>
              </span>
            }
            extra={
              nameError !== "" && (
                <span className="text-danger500">{nameError} </span>
              )
            }
            rules={[
              {
                validator: async (_, value) => {
                  const wordCount = value ? value.trim().split(/\s+/).length : 0
                  if (wordCount > 50) {
                    throw new Error("Description must be 50 words or less")
                  }
                },
              },
            ]}
          >
            <Input
              maxLength={50}
              value={formBodyMultipleEmp.description}
              placeholder="Enter description"
              onChange={(e) => {
                setNameError("")
                setFormBodyMultipleEmp({
                  ...formBodyMultipleEmp,
                  description: e.target.value,
                })
              }}
              onBlur={(e) => {
                {
                  e.target.value &&
                    checkScheduleName({
                      description: e.target.value,
                      id: "-1",
                    })
                }
              }}
            />
          </Form.Item>
        </div>
        <div
          className="relative"
          id="custom-payment-amount"
        >
          <div className="">
            <Form.Item
              label={
                <span className="font-circular text-padeBlack text-sm">
                  Select Employees
                </span>
              }
              name="employees"
              rules={[{ required: true, message: "Please enter this field!" }]}
            >
              <Select
                showSearch
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
                loading={isEmployeeLoading}
                maxTagCount={5}
                onSelect={(value: any) => {
                  const person = employees?.find(
                    (emp) => emp.person_id === value
                  )

                  if (person) {
                    setFormBodyMultipleEmp({
                      ...formBodyMultipleEmp,
                      persons: [
                        ...formBodyMultipleEmp.persons,
                        {
                          person_name: person.full_name,
                          person_id: String(person.person_id),
                          person_bank:
                            person.bank ??
                            (person.routing_code
                              ? `${person.routing_code}(${person.routing_type})`
                              : "-"),
                          person_account_number: person.account_number,
                          amount: "",
                          currency_symbol: person.currency_symbol,
                          currency_code: person.currency_code,
                          source_currency_code: person.currency_code,
                        },
                      ],
                    })
                  }
                }}
                onDeselect={(value: any) => {
                  const filteredPersons = formBodyMultipleEmp.persons?.filter(
                    (emp: any) => emp.person_id !== String(value)
                  )

                  setFormBodyMultipleEmp({
                    ...formBodyMultipleEmp,
                    persons: filteredPersons,
                  })
                }}
                options={employees?.map((person: any) => ({
                  label: (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{person.full_name}</span>
                      <div>
                        <img
                          src={
                            currencies?.find(
                              (item) => item.code === person.currency_code
                            )?.flagUrl || ""
                          }
                          alt={`${person.currency_code} flag`}
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                        />
                        <span>{person.currency_code}</span>
                      </div>
                    </span>
                  ),
                  value: person.person_id,
                  searchValue: person.full_name,
                }))}
                filterOption={(input, option) => {
                  const personName = employees?.find(
                    (emp) => emp.person_id === option?.value
                  )?.full_name

                  return personName
                    ? personName.toLowerCase().includes(input.toLowerCase())
                    : false
                }}
              />
            </Form.Item>
            <div className="mt-3">
              <Form.Item
                name="includeExited"
                valuePropName="checked"
                className="!-mt-4"
              >
                <Checkbox>Include exited employees</Checkbox>
              </Form.Item>
            </div>
          </div>
          {/* Add All Button */}
          <button
            className="text-sm text-primary400 font-circular absolute right-0 top-1"
            onClick={(e) => {
              e.preventDefault()
              const allEmployees = employees?.map((item) => item.person_id)

              form.setFieldValue("employees", allEmployees)

              const body: any = employees?.map((person) => ({
                person_name: person?.full_name,
                person_id: String(person?.person_id),
                person_bank: person.bank
                  ? person.bank
                  : person?.routing_code
                  ? `${person?.routing_code}(${person?.routing_type})`
                  : "-",
                person_account_number: person.account_number,
                amount: "",
                currency_symbol: person?.currency_symbol,
                currency_code: person?.currency_code,
                source_currency_code: person?.currency_code,
              }))

              setFormBodyMultipleEmp((prev: any) => ({
                ...prev,
                persons: [...prev.persons, ...body],
              }))
            }}
            type="button"
          >
            Add all
          </button>
          {/* ======================= */}
          {/* ===Remove All Button=== */}
          {formBodyMultipleEmp?.persons &&
            formBodyMultipleEmp.persons.length > 0 && (
              <button
                className="text-sm text-primary400 font-circular absolute right-16 top-1"
                onClick={(e) => {
                  e.preventDefault()
                  form.setFieldValue("employees", [])
                  setFormBodyMultipleEmp((prev: any) => ({
                    ...prev,
                    persons: [],
                  }))
                }}
                type="button"
              >
                Remove all
              </button>
            )}
        </div>
        {formBodyMultipleEmp.persons.length ? (
          <div className="mt-4 flex flex-col ">
            <span className="font-circular text-padeBlack text-sm  ">
              Employee Amount Settings
              {requiredAmountError && (
                <span className="ml-1 text-xs mt-0.5   text-danger500">
                  (required)
                </span>
              )}
            </span>
            {formBodyMultipleEmp.persons.map((person: any, index: any) => (
              <div className="border border-[#C2C7D0] mb-2 rounded-5px overflow-hidden">
                <div
                  key={index}
                  className={`p-3  cursor-pointer     justify-between w-full  flex items-center ${
                    activeMultiplePersonEmp == person.person_id &&
                    "border-b border-[#C2C7D0] bg-background"
                  }`}
                  onClick={() => {
                    if (activeMultiplePersonEmp != person.person_id) {
                      setActiveMultiplePersonEmp(person.person_id)
                    } else {
                      setActiveMultiplePersonEmp("")
                    }
                  }}
                >
                  <h6 className=" text-neutral700 text-sm font-medium font-avenir">
                    {" "}
                    {person?.person_name}
                  </h6>{" "}
                  <ChevronDownIcon className="w-4 h-4 " />
                </div>
                {activeMultiplePersonEmp == person.person_id && (
                  <div className="px-3 mt-3">
                    <Form layout="vertical">
                      <Form.Item
                        label="Amount"
                        name={`amount-${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Please input amount!",
                          },
                        ]}
                      >
                        <InputNumber<number>
                          formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) =>
                            value?.replace(/,/g, "") as unknown as number
                          }
                          className="w-full font-circular text-neutral400"
                          addonBefore={
                            person?.source_currency_code && (
                              <Select
                                value={person?.source_currency_code}
                                options={[
                                  {
                                    value: person?.currency_code,
                                    label: person?.currency_symbol,
                                  },
                                  ...(person?.currency_code !== "USD"
                                    ? [
                                        {
                                          value: "USD",
                                          label: "$",
                                        },
                                      ]
                                    : []),
                                ]}
                                onChange={(value) => {
                                  const updatedPersons =
                                    formBodyMultipleEmp.persons.map(
                                      (per: any) => {
                                        if (
                                          per.person_id === person.person_id
                                        ) {
                                          return {
                                            ...per,
                                            source_currency_code: value,
                                          }
                                        } else {
                                          return per
                                        }
                                      }
                                    )

                                  setFormBodyMultipleEmp({
                                    ...formBodyMultipleEmp,
                                    persons: updatedPersons,
                                  })
                                }}
                              />
                            )
                          }
                          defaultValue={person?.amount ?? 0}
                          placeholder=""
                          onChange={(e) => {
                            const updatedPersons =
                              formBodyMultipleEmp.persons.map((per: any) => {
                                if (per.person_id === person.person_id) {
                                  return {
                                    ...per,
                                    amount: e,
                                  }
                                } else {
                                  return per
                                }
                              })

                            setFormBodyMultipleEmp({
                              ...formBodyMultipleEmp,
                              persons: updatedPersons,
                            })
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </div>
                )}
              </div>
            ))}{" "}
            {requiredAmountError && (
              <span className=" text-xs mt-0.5   text-danger500">
                All amounts fields are required!
              </span>
            )}
          </div>
        ) : (
          ""
        )}

        {/* ADD Contractor */}
        {/* ADD Contractor */}
        {/* ADD Contractor */}
        {/* ADD Contractor */}
        <AntDesignRadio
          label="Would you like to include contractors in this payment schedule"
          name="access"
          value={addContToPayment}
          required
        >
          <Space direction="horizontal">
            <Radio
              value={true}
              checked={addContToPayment ? true : false}
              onChange={() => {
                setAddContToPayment(true)
              }}
            >
              Yes
            </Radio>
            <Radio
              value={false}
              checked={addContToPayment === false ? true : false}
              onChange={() => {
                setAddContToPayment(false)
              }}
            >
              No
            </Radio>
          </Space>
        </AntDesignRadio>

        {/* CONTRACTORS */}
        {addContToPayment && (
          <div className="">
            <div
              className="mb-4"
              id="custom-payment-amount"
            >
              <Form.Item
                label={
                  <span className="font-circular text-padeBlack text-sm  ">
                    Select Contractors
                  </span>
                }
                name="contractors"
                rules={[
                  { required: true, message: "Please enter this field!" },
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  loading={isContractorsLoading}
                  maxTagCount={5}
                  onSelect={(value: any) => {
                    const person = contractors?.find(
                      (emp) => emp.name === value
                    )

                    if (person) {
                      setFormBodyMultipleCont({
                        ...formBodyMultipleCont,
                        persons: [
                          ...formBodyMultipleCont.persons,
                          {
                            person_name: person?.name,
                            contractor_id: String(person?.contractor_id),
                            person_bank: person.bank
                              ? person.bank
                              : person?.routing_code
                              ? `${person?.routing_code}(${person?.routing_type})`
                              : "-",
                            person_account_number: person.account_number,
                            amount: "",
                            currency_symbol: person?.currency_symbol,
                            currency_code: person?.currency_code,
                            source_currency_code: person?.currency_code,
                          },
                        ],
                      })
                    }
                  }}
                  onDeselect={(value: any) => {
                    const filteredPersons =
                      formBodyMultipleCont.persons?.filter(
                        (emp: any) => emp.person_name !== value
                      )

                    setFormBodyMultipleCont({
                      ...formBodyMultipleCont,
                      persons: filteredPersons,
                    })
                  }}
                  onChange={(values: string[]) => {
                    return []
                  }}
                  options={contractors?.map((person: any) => ({
                    label: (
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{person.name}</span>
                        <div>
                          <img
                            src={
                              currencies?.find(
                                (item) => item.code === person.currency_code
                              )?.flagUrl || "" // Use optional chaining and provide a fallback
                            }
                            alt={`${person?.currency_code} flag`}
                            style={{
                              width: "18px",
                              height: "18px",
                              marginRight: "8px",
                              marginLeft: "8px",
                            }}
                          />
                          <span>{person.currency_code}</span>
                        </div>
                      </span>
                    ),
                    value: person?.name,
                  }))}
                  filterOption={(input, option) => {
                    const personName = contractors?.find(
                      (cont) => cont.name === option?.value
                    )?.name

                    return personName
                      ? personName.toLowerCase().includes(input.toLowerCase())
                      : false
                  }}
                />
              </Form.Item>
            </div>
            {formBodyMultipleCont.persons.length ? (
              <div className="mt-4 flex flex-col ">
                <span className="mb text-padeBlack text-sm  font-circular">
                  Contractor Amount Settings
                  {requiredAmountError && (
                    <span className="ml-1 text-xs mt-0.5   text-danger500">
                      (required)
                    </span>
                  )}
                </span>
                {formBodyMultipleCont.persons.map((person: any, index: any) => (
                  <div className="border border-[#C2C7D0] mb-2 rounded-5px overflow-hidden">
                    <div
                      key={index}
                      className={`p-3  cursor-pointer     justify-between w-full  flex items-center ${
                        activeMultiplePersonCont == person.contractor_id &&
                        "border-b border-[#C2C7D0] bg-background"
                      }`}
                      onClick={() => {
                        if (activeMultiplePersonCont != person.contractor_id) {
                          setActiveMultiplePersonCont(person.contractor_id)
                        } else {
                          setActiveMultiplePersonCont("")
                        }
                      }}
                    >
                      <h6 className=" text-neutral700 text-sm font-medium font-avenir">
                        {" "}
                        {person?.person_name}
                      </h6>{" "}
                      <ChevronDownIcon className="w-4 h-4 " />
                    </div>
                    {activeMultiplePersonCont == person.contractor_id && (
                      <div className="px-3 mt-3">
                        <Form layout="vertical">
                          <Form.Item
                            label="Amount"
                            name={`contractor-amount-${index}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input amount!",
                              },
                            ]}
                            required
                          >
                            <InputNumber<number>
                              formatter={(value) =>
                                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              parser={(value) =>
                                value?.replace(/,/g, "") as unknown as number
                              }
                              className="w-full font-circular text-neutral400"
                              addonBefore={
                                person?.source_currency_code && (
                                  <Select
                                    value={person?.source_currency_code}
                                    options={[
                                      {
                                        value: person?.currency_code,
                                        label: person?.currency_symbol,
                                      },
                                      ...(person?.currency_code !== "USD"
                                        ? [
                                            {
                                              value: "USD",
                                              label: "$",
                                            },
                                          ]
                                        : []),
                                    ]}
                                    onChange={(value) => {
                                      const updatedPersons =
                                        formBodyMultipleCont.persons.map(
                                          (per: any) => {
                                            if (
                                              per.contractor_id ===
                                              person.contractor_id
                                            ) {
                                              return {
                                                ...per,
                                                source_currency_code: value,
                                              }
                                            } else {
                                              return per
                                            }
                                          }
                                        )

                                      setFormBodyMultipleCont({
                                        ...formBodyMultipleCont,
                                        persons: updatedPersons,
                                      })
                                    }}
                                  />
                                )
                              }
                              defaultValue={person?.amount ?? 0}
                              placeholder=""
                              onChange={(e) => {
                                const updatedPersons =
                                  formBodyMultipleCont.persons.map(
                                    (per: any) => {
                                      if (
                                        per.contractor_id ===
                                        person.contractor_id
                                      ) {
                                        return {
                                          ...per,
                                          amount: e,
                                        }
                                      } else {
                                        return per
                                      }
                                    }
                                  )

                                setFormBodyMultipleCont({
                                  ...formBodyMultipleCont,
                                  persons: updatedPersons,
                                })
                              }}
                            />
                          </Form.Item>
                        </Form>
                      </div>
                    )}
                  </div>
                ))}{" "}
                {requiredAmountError && (
                  <span className=" text-xs mt-0.5   text-danger500">
                    All amounts fields are required!
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="flex items-center gap-3 ">
          <Checkbox
            className="text-sm text-[#5D6B82]"
            defaultChecked={false}
            onChange={() => setScheduleConfirmation(!scheduleConfirmation)}
          >
            I confirm the payment details above
          </Checkbox>
        </div>
        <div className="flex  items-center justify-end w-full gap-6  py-4 bg-white border-t mt-14 shadow-footer ">
          <Link to="/dashboard/payment-schedules/custom">
            <Button
              title="Previous"
              color="secondary"
            >
              Cancel
            </Button>
          </Link>

          <Button
            title="Next"
            color="primary"
            submitting={isSubmitting}
            onClick={(e) => {
              e.preventDefault()
              mixPanelEvent("pay-schedule-custom-add-init")

              if (canCreate) {
                handleCreateMultiplePayment()
              } else {
                toast.unauthorized()
              }
            }}
            // disabled={
            //   formBodyMultipleEmp.description == "" ||
            //   formBodyMultipleEmp.persons.length == 0 ||
            //   scheduleConfirmation == false ||
            //   nameError !== ""
            //     ? true
            //     : false
            // }
            disabled={
              !description && formBodyMultipleCont.persons.length == 0
                ? true
                : false
            }
          >
            Proceed
          </Button>
        </div>
      </div>
    </Form>
  )
}

export default MultipleEmployee
